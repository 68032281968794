import React, { useMemo } from 'react'

import { snakeCase } from 'lodash'
import { Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer'

import { ETS_ATTACK_TYPES as ATTACK_TYPES } from 'global/lib/attackTypeValidator/attackTypeValidator'

import { COLORS } from 'ets/configs/styles/defaults'
import baseStyles from 'ets/components/lib/PDFReport/pdfReportStyles'
import PDFFooter, { PDFFooterProps } from 'ets/components/lib/PDFReport/PDFFooter'

export interface DmarcCount {
  id: string
  count: number
  color: string
}

export interface Page2SummaryConfig {
  threatTypesFoundBlob: string
  totalThreatsFoundBlob: string
  employeesWithThreatsBlob: string
  employeesWithThreatsCount: number
  scanStats: {
    [key: string]: string
    completedOn: string
    duration: string
    emailsScanned: string
    threatsDetected: string
  }
  dmarcCounts: DmarcCount[]
}

export interface Page2SummaryProps {
  pdfConfig: Page2SummaryConfig
  intl: any
  footerConfig: PDFFooterProps
}

const styles = StyleSheet.create({
  ...baseStyles,
  summarySection: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 7,
    marginBottom: 5
  },
  summarySectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%'
  },
  summarySectionWrapperFull: {
    display: 'flex',
    flexDirection: 'column'
  },
  summarySectionCount: {
    width: '25%',
    textAlign: 'right',
    fontSize: 30,
    fontWeight: 'bold',
    color: COLORS.RED
  },
  chartWrapper: {
    marginBottom: 10
  },
  threatTypechartWrapper: {
    marginBottom: 5
  },
  threatTypeChart: {
    marginHorizontal: '10%'
  },
  threatDescriptions: {
    marginBottom: 9
  },
  attackTypeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 3
  },
  attackTypeText: {
    ...baseStyles.darkText,
    fontWeight: 'bold',
    fontSize: 7,
    order: 1
  },
  attackTypeDescription: {
    ...baseStyles.darkText,
    fontSize: 7,
    order: 2
  },
  dmarcWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  dmarcPanel: {
    width: '33%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 40
  },
  dmarcRed: {
    ...baseStyles.text,
    fontSize: 34,
    fontWeight: 'bold',
    color: COLORS.RED
  },
  dmarcYellow: {
    ...baseStyles.text,
    fontSize: 34,
    fontWeight: 'bold',
    color: COLORS.YELLOW
  },
  dmarcGreen: {
    ...baseStyles.text,
    fontSize: 34,
    fontWeight: 'bold',
    color: COLORS.LIGHT_GREEN
  },
  dmarcTextWrapper: {
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'column'
  },
  dmarcText: {
    ...baseStyles.boldDarkText,
    fontSize: 7
  },
  dmarcDescription: {
    marginTop: 2,
    ...baseStyles.darkText,
    fontSize: 7,
    maxLines: 2
  }
})

const BASE_I18N_KEY = 'ets.app.pdf.summary'
const ATTACK_TYPES_I18N_KEY = 'app.attack_types'

const Page2Summary: React.FC<Page2SummaryProps> = ({ pdfConfig, footerConfig, intl }) => {
  return useMemo(() => {
    return (
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.title` })}</Text>
        <View style={styles.summaryWrapper}>
          {['completedOn', 'duration', 'emailsScanned', 'threatsDetected'].map((sectionId: string) => (
            <View
              key={sectionId}
              style={styles[sectionId === 'threatsDetected' ? 'summaryElem' : 'summaryElemBordered']}
            >
              <Text style={styles.summaryTitle}>
                {intl.formatMessage({ id: `${BASE_I18N_KEY}.${snakeCase(sectionId)}` })}
              </Text>
              <Text style={styles.summaryValue}>{pdfConfig.scanStats[sectionId]}</Text>
            </View>
          ))}
        </View>
        <View style={styles.summarySection}>
          <View style={styles.summarySectionWrapper}>
            <Text style={styles.subtitle}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.threats_found` })}</Text>
            <Text style={styles.subtitleDescription}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.threats_found_description` })}
            </Text>
          </View>
          <Text style={styles.summarySectionCount}>{pdfConfig.scanStats.threatsDetected}</Text>
        </View>
        {pdfConfig.totalThreatsFoundBlob && (
          <View style={styles.chartWrapper}>
            <Image style={styles.threatTypeChart} src={pdfConfig.totalThreatsFoundBlob} />
          </View>
        )}
        <View style={styles.summarySection}>
          <View style={styles.summarySectionWrapper}>
            <Text style={styles.subtitle}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.employees_with_threats` })}</Text>
            <Text style={styles.subtitleDescription}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.employees_with_threats_description` })}
            </Text>
          </View>
          <Text style={styles.summarySectionCount}>{pdfConfig.employeesWithThreatsCount}</Text>
        </View>
        {pdfConfig.employeesWithThreatsBlob && (
          <View style={styles.chartWrapper}>
            <Image style={styles.threatTypeChart} src={pdfConfig.employeesWithThreatsBlob} />
          </View>
        )}
        <View style={styles.summarySection}>
          <View style={styles.summarySectionWrapperFull}>
            <Text style={styles.subtitle}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.threat_types_found` })}</Text>
            <Text style={styles.subtitleDescription}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.threat_types_found_description` })}
            </Text>
          </View>
        </View>
        {pdfConfig.threatTypesFoundBlob && (
          <View style={styles.threatTypechartWrapper}>
            <Image style={styles.threatTypeChart} src={pdfConfig.threatTypesFoundBlob} />
          </View>
        )}
        <View style={styles.threatDescriptions}>
          {[
            ATTACK_TYPES.CONVERSATION_HIJACKING,
            ATTACK_TYPES.EXTORTION,
            ATTACK_TYPES.IMPERSONATION,
            ATTACK_TYPES.PHISHING,
            ATTACK_TYPES.SCAMMING
          ].map((attackType: string) => (
            <View style={styles.attackTypeWrapper} key={attackType}>
              <Text style={styles.attackTypeText}>
                {`${intl.formatMessage({ id: `${ATTACK_TYPES_I18N_KEY}.${attackType}.text` })} - `}
              </Text>
              <Text style={styles.attackTypeDescription}>
                {intl.formatMessage({ id: `${ATTACK_TYPES_I18N_KEY}.${attackType}.tooltip` })}
              </Text>
            </View>
          ))}
        </View>
        <View style={styles.summarySection}>
          <View style={styles.summarySectionWrapperFull}>
            <Text style={styles.subtitle}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.domain_dmarc_status` })}</Text>
            <Text style={styles.subtitleDescription}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.domain_dmarc_status_description` })}
            </Text>
          </View>
        </View>
        <View style={styles.dmarcWrapper}>
          {pdfConfig.dmarcCounts.map((dmarcConfig: DmarcCount) => (
            <View key={dmarcConfig.id} style={styles.dmarcPanel}>
              <Text style={styles[dmarcConfig.color]}>{dmarcConfig.count}</Text>
              <View style={styles.dmarcTextWrapper}>
                <Text style={styles.dmarcText}>
                  {intl.formatMessage({ id: `${BASE_I18N_KEY}.dmarc.${dmarcConfig.id}` })}
                </Text>
                <Text style={styles.dmarcDescription}>
                  {intl.formatMessage({ id: `${BASE_I18N_KEY}.dmarc.${dmarcConfig.id}_description` })}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <PDFFooter page={1} intl={intl} {...footerConfig} />
      </Page>
    )
  }, [pdfConfig, footerConfig, intl])
}

export default Page2Summary
