/* eslint-disable @typescript-eslint/naming-convention, quotes */
export const ADD_PERMISSION = 'Add permissions'
export const NO_PERMISSIONS =
  'You do not currently have permissions to access this page. Please contact Barracuda support for assistance.'
export const AUTH_CODE_ERROR = 'The authentication code you provided is incorrect. Please try again.'
export const BARRACUDA = 'Barracuda'
export const BARRACUDA_EMAIL_PROTECTION = 'Barracuda Email Protection'
export const CHANGES_SAVED = 'Changes saved'
export const MICROSOFT_365 = 'Microsoft 365'
export const CONNECT_TO_O365 = `Connect to ${MICROSOFT_365}`
export const DEFAULT_ERROR = 'Oops, something went wrong'
export const DEMO_ERROR = 'This operation is not permitted in a demo account.'
export const DMARC = 'DMARC'
export const EMAIL = 'Email'
export const HEADERS = 'Headers'
export const LOADING = 'Loading'
export const SENDER = 'Sender'
export const SIGNIN_TITLE = 'Sign in with your Barracuda account'
export const FORGOT_MY_PASSWORD = 'Forgot my password'
export const SWITCH_ACCOUNT = 'Use another account'
export const SUBJECT = 'Subject'
export const DATE = 'Date'
export const RECIPIENT = 'Recipient'
export const SEARCH = 'Search'
export const SIGN_IN = 'Sign in'
export const CONVERSATION_HIJACKING_1 = 'This email is potentially part of a conversation hijacking attack'
export const CONVERSATION_HIJACKING_2 =
  'This email has a {reason} domain <b>{badDomain}</b> that appears to be impersonating the domain {domain}'
export const LINKING_CODE_INVALID = 'A valid linking code is required'
export const SERIAL_NUMBER_INVALID = 'A valid serial number is required'
export const MORE_DETAILS = 'More details'
export const REPORT_FALSE_POSITIVE = 'Report false positive'
export const NAME = 'Name'
export const UNKNOWN = 'Unknown'
export const REVIEW_LICENSES = 'Review licenses'
export const ACTIVATE_TRIAL = 'Activate your free trial'

export const MARKED_AS_FP = 'Marked as false positive'
export const FROM = 'From'

export const YES = 'Yes'
export const NO = 'No'
export const NA = 'N/A'

// Begin button strings
export const ADD = 'Add'
export const APPLY = 'Apply'
export const BACK = 'Back'
export const CANCEL = 'Cancel'
export const CLOSE = 'Close'
export const CREATE = 'Create'
export const DOWNLOAD = 'Download'
export const NEXT = 'Next'
export const SAVE = 'Save'
export const TEST = 'Test'
export const START_TRIAL = 'Start free trial'
export const CONTINUE = 'Continue'
export const SUBMIT = 'Submit'
export const SKIP = 'Skip'
// End button strings

// Begin Date/Time strings
export const LAST_DAY = 'Last day'
export const LAST_24_HOURS = 'Last 24 hours'
export const LAST_7_DAYS = 'Last 7 days'
export const LAST_30_DAYS = 'Last 30 days'
export const LAST_90_DAYS = 'Last 90 days'
export const LAST_6_MONTHS = 'Last 6 months'
export const LAST_X_DAYS = 'Last {days} days'

// Begin UI form errors
export const EMAIL_ERROR = 'A valid email is required'
// End UI form errors

export const SEN_APP_NAME = 'Impersonation Protection'
export const DFP_APP_NAME = 'Domain Fraud Protection'
export const FIR_APP_NAME = 'Incident Response'
export const ETS_APP_NAME = 'Email Threat Scanner'

export default {
  app: {
    next: NEXT,
    back: BACK,
    close: CLOSE,
    email: EMAIL,
    subject: SUBJECT,
    from: 'From',
    loading: LOADING,
    or: 'or',
    password: 'Password',
    verify: 'Verify',
    copy_to_clipboard: {
      button: 'Copy to clipboard',
      copied: 'Copied'
    },
    attack_types: {
      all_attack_types: {
        text: 'All attack types'
      },
      all_threats: {
        text: 'All threats'
      },
      advanced_threats: {
        text: 'Advanced threats'
      },
      extortion: {
        text: 'Extortion',
        tooltip:
          'An attempt to obtain money or something of value by threatening to release embarrassing personal information, like images or videos.'
      },
      impersonation: {
        text: 'Impersonation',
        tooltip:
          'An attack where the malicious actor pretends to be a person, organization, or service to entice the victim to wire money, buy gift cards, or disclose business information.'
      },
      phishing: {
        text: 'Phishing',
        tooltip:
          'An attempt to trick victims into believing a message is from a trusted organization to get them to disclose sensitive information, like credentials or banking information.'
      },
      scamming: {
        text: 'Scamming',
        tooltip:
          'Emails used by cybercriminals to defraud victims or steal their identity by tricking them into disclosing personal information.'
      },
      scamming_graymail: {
        text: 'Scamming/Graymail',
        tooltip:
          'Emails used by cybercriminals to defraud victims or steal their identity by tricking them into disclosing personal information.'
      },
      conversation_hijacking: {
        text: 'Conversation hijacking',
        tooltip:
          'An attack where cybercriminals insert themselves into existing business conversations, or initiate new ones, to steal money or personal information.'
      }
    },
    attack_statuses: {
      moved_to_junk_folder: 'Moved to junk folder',
      alerted_recipient: 'Recipient alerted',
      alerted_sentinel_admin: 'System admin alerted',
      alerted_impersonated_sender: 'Impersonated sender alerted',
      deleted: 'Message deleted',
      marked_as_false_positive: MARKED_AS_FP,
      reviewed: 'Reviewed',
      not_reviewed: 'Not reviewed',
      older_than_30_days: 'Older than 30 days',
      failed_to_delete: 'Failed to delete',
      in_progress: 'In progress',
      marked_as_false_positive_remediation: "Marked as false positive (won't be deleted)"
    },
    incident_statuses: {
      fo: 'Not quarantined',
      ip: 'In progress',
      nf: 'Malicious email not found',
      er: 'Malicious email not found',
      qu: 'Quarantined',
      de: 'Deleted',
      no: 'Notification email sent',
      external_er: 'Error sending notification'
    },
    dmarc_statuses: {
      unverified: 'Unverified',
      unverified_tooltip: 'Verify that you own domain to continue configuration',
      not_configured: 'Not configured',
      not_configured_tooltip: 'Configure domain to enable DMARC reporting',
      reporting_only: 'Reporting only',
      reporting_only_tooltip: 'Domain is set to reporting mode only',
      protected: 'Protected',
      protected_tooltip: 'Domain is in enforcement mode'
    },
    connect_o365_dialog: {
      cancel: CANCEL,
      connect_to_o365: CONNECT_TO_O365,
      connect_o365: `Connect your Barracuda account to ${MICROSOFT_365}.`,
      connect_o365_selection: `Connect your Barracuda account to ${MICROSOFT_365}. You must have an ${MICROSOFT_365} global administrator account to authorize.`,
      generate_report: 'Generate a report on past attacks for your account.',
      microsoft_explainer:
        'After you sign into your Microsoft account, accept the application <a>permissions</a> to continue.',
      o365_admin_required: `You must have an ${MICROSOFT_365} global administrator account to authorize.`,
      account_want_to_connect: 'Account to connect',
      sentinel: {
        title: `I want to protect my account with ${SEN_APP_NAME}`,
        subtitle: `Secure your account in real-time with ${SEN_APP_NAME}.`
      },
      ets: {
        title: 'I want to run an Email Threat Scan',
        subtitle: 'Generate a report on past attacks for your account.'
      }
    },
    email_details_dialog: {
      title: 'Message details',
      marked_as_false_positive: MARKED_AS_FP,
      back: BACK,
      back_to_top_tooltip: 'Back to top',
      report_false_positive: 'Report false positive',
      search_for_similar_messages: 'Find similar messages',
      download_email: 'Download email',
      analysis: 'Analysis',
      action_taken: 'Action taken',
      severity: 'Severity',
      severity_tooltip:
        'The seriousness of this threat, based on its attack type. For example, an impersonation attack will always have a higher severity score than spam.',
      confidence: 'Confidence',
      confidence_tooltip:
        'Measure of the likelihood that this email is an actual threat, based on internal classifiers and calculations.',
      determination: 'Determination',
      key_indicators: 'Key indicators',
      to: 'To',
      from: 'From',
      reply_to: 'Reply to',
      date: 'Date',
      subject: 'Subject',
      analysis_items: {
        has_from_impersonation: "The <b><i>from</i></b> address is not {name}'s typical address",
        has_reply_to_impersonation: "The <b><i>reply-to</i></b> address is not {name}'s typical address",
        hello_url: 'This email contains a personalized phishing URL',
        ceo_or_ext_ceo: 'This email makes an unusual request to the recipient',
        scamming: 'This email uses language usually associated with frauds and scams',
        scamming_graymail: 'This email uses language usually associated with frauds and scams',
        blackmailing: 'This email requests payment through crypto currency',
        blackmailing_2: 'This email makes unusual threats to the recipient',
        attachment_attack: 'This email has a suspicious call-to-action',
        attachment_attack_2: 'This email has an attachment that might contain phishing or malware',
        service_phishing: 'This email has a suspicious call-to-action',
        service_phishing_2: 'This email contains a suspicious URL<if>:<br><b>{domains}</b></br></if>',
        service_phishing_with_keywords: '<b>{keyword}</b> does not typically use this email address to send messages',
        service_phishing_with_keywords_2:
          'This email contains a suspicious URL that <b>{keyword}</b> does not typically use',
        domain_impersonation: CONVERSATION_HIJACKING_1,
        domain_impersonation_2: CONVERSATION_HIJACKING_2,
        conversation_hijacking: CONVERSATION_HIJACKING_1,
        conversation_hijacking_2: CONVERSATION_HIJACKING_2,
        attachment_conversation_hijacking: CONVERSATION_HIJACKING_1
      }
    },
    connect_o365_page: {
      accept_permissions:
        'After you sign in to your Microsoft account, accept the application permissions to continue.',
      connect_to_o365: CONNECT_TO_O365,
      o365_admin_required: `You must have an ${MICROSOFT_365} global administrator account to authorize.`,
      privacy_pdf: 'Learn what happens next and how we protect your data and privacy',
      switch_account: SWITCH_ACCOUNT
    },
    custom_ranges: {
      last_hour: 'Last hour',
      last_12_hours: 'Last 12 hours',
      last_24_hours: 'Last 24 hours',
      last_2_days: 'Last 2 days',
      last_7_days: 'Last 7 days',
      last_30_days: 'Last 30 days'
    },
    email_details: {
      copied: 'Copied',
      copy_to_clipboard: 'Copy to clipboard',
      date: 'Date',
      email_error: 'Unable to load email details',
      email_from: 'Email from {sender} on {date}',
      headers: HEADERS,
      parameter: 'Parameter',
      outbound_no_header: 'Outbound messages do not contain message headers',
      outbound_no_threat_details: 'Outbound messages do not contain threat details',
      recipients: 'Recipients',
      sender: SENDER,
      subject: SUBJECT,
      type: 'Type',
      name: 'Name',
      content_type: 'Content type',
      inline: 'Inline',
      yes: YES,
      no: NO,
      size: 'Size',
      tabs: {
        statistics: 'Statistics',
        email: EMAIL,
        headers: HEADERS,
        threat_details: 'Threat details',
        failure_report: 'Failure report',
        attachments: 'Attachments ({count})'
      },
      statistics_tab: {
        fields: {
          domain: 'Domain',
          isDkimAuthenticated: 'DKIM',
          isDmarcAuthenticated: DMARC,
          isDmarcAlignmentAuthenticated: DMARC,
          isSpfAuthenticated: 'SPF',
          senderDomainRegistrationDate: 'Domain registered on',
          senderIpAddress: 'IP address',
          senderIpLocation: 'IP location',
          senderIpReputation: 'IP reputation score',
          threatsDetectCount: 'threat(s) detected'
        },
        sender_analysis: 'Sender analysis',
        sender_authentication: 'Sender authentication',
        pass: 'Pass',
        fail: 'Fail',
        none: 'None'
      },
      threats: {
        dmarc: 'DMARC compliance',
        spf: 'SPF IP check',
        dkim: 'DKIM alignment'
      },
      value: 'Value'
    },
    expiration_banner: {
      free_trial_expired: 'Free trial expired: {expiryDate}',
      free_trial_expires: 'Free trial expires: {expiryDate}',
      subscription_expired: 'Subscription expired: {expiryDate}',
      subscription_expires: 'Subscription expires: {expiryDate}'
    },
    maintenance_banner: {
      content:
        'We are performing work upgrading our databases to keep our products reliable and fast. We apologize for any inconvenience. For more information, or if you have any questions, please contact <a>support</a>.'
    },
    feature_banner: {
      title: "What's new",
      subtitle:
        "We're excited to introduce new reports in Impersonation Protection, complete with actionable recommendations to boost your security! These will be available to all customers by end of May 2024. Please begin scheduling your new reports soon. Note that the current reports will be phased out starting July 2024. <a>Learn More</a>",
      dismiss: 'Dismiss'
    },
    filters: {
      curio_contains: 'Contains',
      curio_contains_phrase: 'Contains phrase',
      django_contains: 'Contains',
      django_does_not_contain: 'Does not contain',
      django_remediated: 'Remediated',
      django_not_remediated: 'Not remediated',
      kotlin_contains: 'Contains',
      kotlin_does_not_contain: 'Does not contain'
    },
    grid: {
      pager: {
        items_per_page: 'Items per page',
        goto_first_page: 'Go to the first page',
        goto_prev_page: 'Go to the previous page',
        goto_next_page: 'Go to the next page',
        goto_last_page: 'Go to the last page',
        limit: 'Only the first 10,000 records are displayed'
      }
    },
    signin_signup: {
      alternate_layout: {
        o365_button: `Sign up with ${MICROSOFT_365}`,
        subtitle: `${MICROSOFT_365} global admin access is required.`
      },
      form: {
        demo: 'Request a demo',
        permission: 'Learn about the <perm>permissions</perm> we need',
        subtitle_1: 'Try <b>Email Protection Premium Plus</b> trial for FREE for 14-days',
        subtitle_2: 'No credit card is required',
        trial_button: 'Start trial'
      },
      permission_dialog: {
        subtitle: `When you create a Barracuda account to start securing your ${MICROSOFT_365} email users, a Microsoft page will ask you to grant a number of permissions.`,
        list_main_text: 'Your privacy and security are a priority for us. We keep you safe in the following ways',
        list_item_1: `Barracuda never gains access to your ${MICROSOFT_365} credentials.`,
        list_item_2: `{appName} leverages the widely-used Oauth protocol to authenticate with ${MICROSOFT_365}.`,
        list_item_3:
          "Barracuda's access is limited to the information needed for the purpose of detecting and remediating threats.",
        list_item_4:
          '{appName} pulls metadata about users, mail folders, and emails only for the purpose of displaying the information in your report.'
      },
      switch_account: SWITCH_ACCOUNT
    },
    feedback: {
      title: 'Please confirm the email you received was not phishing',
      text:
        "Click the button below to let us know that the email you received was a legitimate email, and not a phishing attack. Your feedback will help make our system more accurate. If the email was previously moved to the user's junk folder, it will be returned to its original location. Thanks.",
      buttonText: 'This was not phishing',
      successTitle: 'Thanks for the feedback!',
      successText:
        "We noted that the email you received was not a phishing attack. Our team will investigate your report so we can improve our artificial intelligence system. If the email was previously moved to the user's junk folder, it will be returned to its original location. We appreciate your feedback."
    },
    contest: {
      title: 'How many scans?',
      text:
        'To get the status of Email Threat Scans that were made from your personal link, type in your email below. We will send the status report to the email you provide.',
      buttonText: 'Find out how many',
      successTitle: 'Thanks!',
      successText:
        'We will send a status report to {email} shortly. If you have any questions, please reach out to ets_support_team@barracuda.com.'
    },
    contest_partner_portal: {
      error: 'Something went wrong. Please try again.',
      title: 'Get a report of your MyLink usage',
      text:
        'To get the status of Email Threat Scans, type in your email below. We will send the status report to the email you provide.',
      buttonText: 'GET REPORT',
      successTitle: 'Thanks!',
      successText:
        'We will send a status report to {email} shortly. If you have any questions, please reach out to ets_support_team@barracuda.com.'
    },
    signup_complete: {
      title: 'Complete your Barracuda account',
      phone_number: 'Phone number',
      agree_privacy_policy: 'I agree to the <a>Barracuda Network Privacy Policy.</a>',
      scan_now: 'Scan now',
      continue: CONTINUE,
      placeholders: {
        password: 'Minimum {char} characters',
        confirm_password: 'Confirm password'
      },
      form_errors: {
        weak_password:
          'Your password needs to have: <li>- 8 characters</li><li>- 1 capital letter</li><li>- 1 digit</li>',
        password_match: "The password doesn't match",
        invalid_phone: 'Invalid phone number'
      }
    },
    signup_complete_policy: {
      title: 'Barracuda Networks privacy policy'
    },
    layout: {
      admin: 'Admin',
      fir: FIR_APP_NAME,
      ets: ETS_APP_NAME,
      sen: SEN_APP_NAME,
      dfp: DFP_APP_NAME,
      impersonation_banner: "Caution: impersonation mode, changes can affect {user}'s account.",
      impersonation_banner_button: 'Back',
      button1_text: CANCEL,
      button2_text: '+ Add MS account',
      button3_text: 'Manage accounts'
    },
    login: {
      signin_title: SIGNIN_TITLE,
      email: 'Your email',
      password: 'Your password',
      choose_password: 'Choose a password',
      signin: SIGN_IN,
      get_started: 'Get started',
      create_account: 'Create an account',
      have_account: 'I already have a Barracuda account',
      by_clicking_agree: 'By clicking "Get started", you agree to our',
      privacy_policy: 'Privacy policy',
      forgot_password: 'Forgot your password?',
      login_failed: 'The email and password you provided do not match our records. Please try again.',
      helper_text_login_error: 'Invalid email and/or password. Please try again.'
    },
    login_otp: {
      title: 'Two-Factor authentication',
      subtitle: 'Enter the code from the two-factor authentication app on your mobile device.',
      authentication_code: 'Authentication code',
      authentication_code_required: 'A valid authentication code is required',
      footer: 'To reset your Barracuda two-factor authentication, contact <a>Barracuda support</a> at +1 408 342 5300.'
    },
    logoutmenu: {
      serial_number: 'Serial number: {serial}',
      expiration: 'Expiration: {expiration}',
      manage_users: 'Manage users',
      connect_office: `Connect ${MICROSOFT_365} account`,
      sign_out: 'Sign out',
      review_licenses: REVIEW_LICENSES
    },
    profile: {
      company: 'Company',
      continue: CONTINUE,
      country: 'Country',
      email: EMAIL,
      name: 'Name',
      phone: 'Phone number',
      switch_account: SWITCH_ACCOUNT,
      zip_code: 'Zip code'
    },
    navbar: {
      new: 'New',
      add: ADD,
      impersonation_banner: "Caution: impersonation mode, changes can affect {user}'s account.",
      back: BACK,
      noticeable_header: 'New in Incident Response',
      noticeable_footer: 'View more updates',
      noticeable_title: "What's new",
      changes_saved: CHANGES_SAVED
    },
    switch: {
      yes: YES,
      no: NO
    },
    mode_handler_selector: {
      enforcement_mode: 'Enforcement mode (recommended)',
      enforcement_mode_description: `${SEN_APP_NAME} will remediate attacks in real time by putting it in the recipient's junk email folder and sending alerts to you and to the recipient. You will be able to customize this behavior.`,
      reporting_mode: 'Reporting mode',
      reporting_mode_description: `${SEN_APP_NAME} will keep track of attacks and make them visible in your dashboard, but will not take any remediation action in real time. You will not be protected from spear phishing and fraud.`,
      footer: 'You can change this setting at any time by clicking {icon} in the {appName} dashboard.'
    },
    export_to_csv: {
      title: 'Export to CSV',
      export_tooltip: 'Only the first {exportLimit} mailbox records will be exported.'
    },
    score: {
      level: {
        1: 'Very low',
        2: 'Low',
        3: 'Moderate',
        4: 'High',
        5: 'Very high'
      }
    },
    error: {
      save_error: 'Unable to save changes',
      no_permissions: NO_PERMISSIONS,
      address_book_already_created:
        'There was a problem creating an address book in Barracuda Security Awareness Training. Please try again in one minute.',
      default: 'Oops, something went wrong!',
      validation_issue: 'Invalid data',
      o365_already_connected: `This ${MICROSOFT_365} account is already connected to Barracuda Networks. Please contact your ${MICROSOFT_365} admin.`,
      account_already_exists: 'A Barracuda account associated with this email already exists.',
      login_failed: 'We do not recognize your email and/or password. Please try again.',
      o365_account_already_connected: `This ${MICROSOFT_365} account is already connected to Barracuda Networks. Please contact your ${MICROSOFT_365} admin.`,
      account_already_associated: 'A Barracuda account associated with this email already exists.',
      user_already_exists: 'User with this email is already registered, please try to login.',
      bad_password_exception: 'The password you chose is commonly used and is not secure. Please choose another one.',
      operation_not_permitted_on_demo_account: 'This operation is not permitted in a demo account',
      domain_already_exists: 'Domain already exists',
      incorrect_authentication: AUTH_CODE_ERROR,
      user_create_dpl_check_failure:
        'We are processing your request and will contact you for more information. You can also contact us at +1 866 241 8876.',
      unable_to_load_scan: 'Unable to load scan data. Contact Barracuda Support for assistance.',
      unable_to_load_threats: 'Unable to load threats data',
      account_is_read_only: 'Account is read-only',
      account_exists_on_sentinel: `Account is already on ${SEN_APP_NAME}`,
      demo_error: DEMO_ERROR,
      duplicate_whitelist: 'Allowed sender already exists',
      test_ai_error: 'Unable to send test email',
      user_does_not_exist:
        "Unable to log in. Contact your organization's Barracuda administrator, who can provision Barracuda products in Barracuda Cloud Control.",
      serial_linking_code_invalid: 'The serial or linking code supplied is invalid',
      inbox_rules_final_error: `${MICROSOFT_365} does not allow removing this item. Please try using PowerShell`,
      inbox_rules_delete_failed: `Sentinel encountered an error on ${MICROSOFT_365} while attempting to remove the item. Please try again.`,
      connect_o365_dialog: {
        account_already_exists: `${MICROSOFT_365} account already associated`,
        default: `Unable to connect to ${MICROSOFT_365}`,
        o365_access_token_expired: `${MICROSOFT_365} access token expired`,
        o365_invalid_client: `${MICROSOFT_365} invalid client`,
        o365_already_connected: `${MICROSOFT_365} account already connected`,
        o365_cancel_other: `${MICROSOFT_365} cancelled connect`,
        o365_state_token_mismatch: `${MICROSOFT_365} state token mismatch`,
        o365_user_cancel: 'User declined to consent',
        echo_activation_required: 'You must activate this account in the Barracuda ECHO platform.',
        echo_links:
          'Click your location to continue:<br><echoNALink></echoNALink> <echoOutsideNALink></echoOutsideNALink></br>',
        other: 'Other',
        north_america: 'North America'
      },
      no_accesstokens: `Your account is not associated with any ${MICROSOFT_365} environments. Please reach out to your company's Barracuda admin to be added to your company's account.`,
      incident_does_not_exist: 'Incident does not exist',
      report_does_not_exist: 'Unable to schedule report, this report has been deleted',
      unified_reporting_demo_error: DEMO_ERROR,
      snackbar_errors: {
        default: {
          title: 'Oops, something went wrong',
          content: 'If the issue persists, please contact Barracuda support'
        },
        unified_reporting_demo_error: {
          title: 'Unable to perform operation.',
          content: DEMO_ERROR
        },
        report_does_not_exist: {
          title: 'Unable to schedule report.',
          content: 'This report has been deleted.'
        }
      },
      invalid_refresh_token: 'The refresh token is invalid.',
      request_report_failed: 'Unable to request report. Please try again.',
      request_report_invalid: 'Invalid data for report. Please contact Barracuda Support for assistance.'
    },
    product_types: {
      impersonation_protection: SEN_APP_NAME,
      domain_fraud_protection: DFP_APP_NAME,
      incident_response: FIR_APP_NAME,
      email_threat_scanner: ETS_APP_NAME
    },
    inbox_rules: {
      actions: {
        assign_categories: 'Assign categories: {values}',
        copy_to_folder: 'Copy message to folder',
        delete: 'Delete message',
        forward_as_attachment_to: 'Forward as attachment to: {values}',
        forward_to: 'Forward to: {values}',
        mark_as_read: 'Mark as read',
        mark_importance: 'Mark importance as: {values}',
        move_to_folder: 'Move message to folder',
        permanent_delete: 'Permanently delete',
        redirect_to: 'Redirect to: {values}',
        stop_processing_rules: 'Stop processing rules'
      },
      conditions: {
        body_contains: 'Body contains: {values}',
        body_or_subject_contains: 'Body or subject contains: {values}',
        categories: 'Categories: {values}',
        from_addresses: 'From addresses: {values}',
        has_attachments: 'Has attachments',
        header_contains: 'Header contains {values}',
        importance: 'Importance: {value}',
        is_approval_request: 'Is approval request',
        is_automatic_forward: 'Is automatic forward',
        is_automatic_reply: 'Is automatic reply',
        is_encrypted: 'Is encrypted',
        is_meeting_request: 'Is meeting request',
        is_meeting_response: 'Is meeting response',
        is_non_delivery_report: 'Is non-delivery report',
        is_permission_controlled: 'Is permission controlled',
        is_read_receipt: 'Is read receipt',
        is_signed: 'Is signed',
        is_voicemail: 'Is voicemail',
        message_action_flag: 'Message action flag: {values}',
        not_sent_to_me: 'Not sent to me',
        recipient_contains: 'Recipient contains: {values}',
        sender_contains: 'Sender contains: {values}',
        sensitivity: 'Sensitivity: {value}',
        sent_cc_me: 'Sent CC to me',
        sent_only_to_me: 'Sent only to me',
        sent_to_addresses: 'Sent to addresses: {values}',
        sent_to_me: 'Sent to me',
        sent_to_or_cc_me: 'Sent to or CC me',
        subject_contains: 'Subject contains: {values}',
        within_size_range: 'Within a certain size range'
      }
    },
    time_periods: {
      one_hour: '1 hour',
      three_hours: '3 hours',
      twelve_hours: '12 hours',
      one_day: '1 day',
      one_week: '1 week',
      one_month: '1 month'
    },
    unified_reporting: {
      buttons: {
        edit_columns: 'Edit columns',
        export_as: 'Export as',
        save: SAVE,
        save_as: 'Save as'
      },
      column_categories: {
        barracuda: 'Barracuda',
        cloudgen_policy: 'Policies created in CloudGen',
        egd_policy: 'Policies created in Email Gateway',
        email: 'Email',
        event: 'Event',
        general: 'General',
        incident_options: 'Incident options',
        recipient: 'Recipient',
        run: 'Run',
        search_criteria: 'Search criteria',
        sender: 'Sender',
        workflow: 'Workflow'
      },
      data_table: {
        search: SEARCH,
        date_range: 'Date range',
        date_range_retention_error: 'Date ranges must be within the last 180 days.',
        date_range_empty_date_error: "Date range can't be empty",
        operators: {
          between: 'Between',
          blank: 'Is blank',
          contains: 'Contains',
          date_equals: 'Is equal to',
          equals: 'Is equal to',
          greater: 'After',
          in: 'Is equal to',
          less: 'Before',
          not_blank: 'Is not blank',
          not_contains: 'Does not contains',
          not_in: 'Not equal to'
        },
        boolean: {
          true: YES,
          false: NO
        },
        column_filters: {
          condition_label: 'Condition',
          filter_title: 'Filter',
          min_max_filter: {
            placeholder: 'Enter value'
          },
          select_all_label: 'Select all'
        }
      },
      data_table_header: {
        display_top_results: {
          dropdown_header: 'Display',
          dropdown_menu_items: {
            top_5: 'Top 5',
            top_10: 'Top 10',
            top_15: 'Top 15',
            top_25: 'Top 25',
            top_30: ' Top 30',
            top_50: 'Top 50'
          }
        }
      },
      save_report_dialog: {
        date_range_empty_date_error: "Date range can't be empty",
        date_range_warning_text:
          'The selected date range is unreachable. Select a valid date range to save this report',
        dialogTitle: 'Save report',
        dialogSubtitle:
          'Enter a name to save this report and it’s current selected parameters.  You can access this report in the future under My Reports.',
        learn_more: 'Learn more about report parameters',
        label: 'Report Name',
        buttons: {
          save: SAVE,
          close: CLOSE
        },
        dataRetentionWarning: `Due to our data retention policy, you could lose data in this report in the future.  To retain the data, download this report as a CSV before {dataLossDate}.`
      },
      rename_report_dialog: {
        title: 'Rename report',
        subtitle: 'Enter a name to save it under My reports',
        label: 'Report Name',
        buttons: {
          save: SAVE,
          cancel: CANCEL
        }
      },
      empty_state_text: {
        error: 'Chart data not available at the moment, try again later.',
        loading: LOADING,
        no_results: 'No matching results'
      },
      column_menu: {
        title: 'Table columns',
        subtitle: 'Select columns to add'
      },
      sidemenu: {
        title: 'Reports',
        search_placeholder: 'Search report',
        my_reports: 'My reports',
        barracuda_reports: "Barracuda's reports"
      },
      chart: {
        tooltip: {
          bar_chart: 'Bar graph',
          line_chart: 'Line graph',
          stacked_bar_chart: 'Stacked bar graph',
          grouped_bar_chart: 'Grouped bar graph',
          table_chart: 'Table graph'
        }
      },
      date_range_picker: {
        last_day: LAST_DAY,
        last_24_hours: LAST_24_HOURS,
        last_7_days: LAST_7_DAYS,
        last_30_days: LAST_30_DAYS,
        last_90_days: LAST_90_DAYS,
        last_6_months: LAST_6_MONTHS,
        day_range: LAST_X_DAYS
      }
    },
    scheduled_reports: {
      title: 'Scheduled reports',
      empty_state_text: 'No scheduled reports found.',
      new_scheduled_report: 'New schedule',
      search_placeholder: 'Search report names and recipients',
      frequency: 'Frequency',
      schedule_frequency: '{relativeDateRange} {frequency} report scheduled {date}',
      disabled: 'Disabled',
      last_sent: 'Last sent on: {date} at {time}',
      never_sent: `Last sent on: ${NA}`,
      next_schedule: 'Next schedule: {date} at {time}',
      chip_extra_recipients: '+{count} more',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      delete_schedule: 'Delete',
      open_report: 'Open report',
      disable_schedule: 'Disable',
      enable_schedule: 'Enable',
      schedule_title: '{reportName} | {format}',
      relative_date_range: {
        last_day: LAST_DAY,
        last_24_hours: LAST_24_HOURS,
        last_x_days: LAST_X_DAYS,
        last_6_months: LAST_6_MONTHS
      },
      schedule_date_time: {
        daily: 'at {scheduledTime} for',
        weekly: 'on {scheduledDayOfWeek} at {scheduledTime} for',
        monthly: 'every {frequencyMonthly} {scheduledDayOfWeek} at {scheduledTime} for',
        specific_date: 'on the {frequencyDayOfMonth} at {scheduledTime} for',
        last_day_of_month: 'last day of the month'
      },
      alert: {
        success: {
          create: {
            title: 'New schedule',
            content: 'New schedule successfully created.'
          },
          update: {
            title: 'Schedule updated',
            content: 'Your changes have successfully saved.'
          }
        }
      },
      schedule_report_dialog: {
        title: {
          new_schedule: 'New schedule',
          update_schedule: 'Edit scheduled report'
        },
        button: {
          cancel: CANCEL,
          save: SAVE,
          schedule: 'Schedule'
        },
        helper_text: {
          delays_may_occur: 'Delays may occur',
          invalid_email: 'Enter a valid email address',
          limit_50_emails: 'Too many recipients, the maximum number of allowed recipients is 50'
        },
        label: {
          format: 'Format',
          recipients: 'Recipients',
          report_name: 'Report name',
          time_range: 'Time range'
        },
        reportNameListSubheader: {
          my_reports: 'My reports',
          barracuda_reports: 'Barracuda reports'
        },
        at: 'At',
        day: 'Day',
        every: 'Every',
        frequency: 'Frequency',
        last_day_of_month: 'Last day of the month',
        last_updated: 'Last updated: ',
        on: 'On'
      }
    },
    date_range_selector: {
      day_range: 'Last {range} days',
      hour_range: 'Last {range} hours',
      last_hour: 'Last hour',
      last_day: 'Last day',
      last_week: 'Last week',
      last_month: 'Last month'
    }
  }
}
/* eslint-enable @typescript-eslint/naming-convention, quotes */
