import { Action } from '@reduxjs/toolkit'
import queryString from 'query-string'

import { User } from 'global/types/api/userType'
import { setSuccessCallbacks, setFailedCallbacks, setUserFlags, logout } from 'global/redux/features/auth/authSlice'
import accessTokenLib from 'global/lib/accessToken/accessToken'
import { UiRoutes } from 'global/lib/routes/routesConfig'
import { RootState } from 'global/redux/toolkit/store'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import validateUser from 'global/components/lib/authLayer/validateUser'

export interface UpdateStores {
  user: User
  accessTokenId?: string
  isSignupUser?: boolean
  isSigninAction?: boolean
}

export interface GotoReportPage {
  user?: User
  scan?: any
  accessTokenId: string
}

export interface SetAuthCallbacks {
  routesConfig: UiRoutes
  setUser: (user: User) => Action
  gotoReportPage: (params: GotoReportPage) => void
}

export default function setAuthCallbacks({ routesConfig, setUser, gotoReportPage }: SetAuthCallbacks) {
  async function doValidateUser(payload: UpdateStores) {
    validateUser(payload, { routesConfig, setUser, gotoReportPage })
  }

  function sendSigninAnalyticsEvent(user: User, { getState }: any) {
    const { auth } = getState() as RootState
    if (user.mfa || auth.NEEDS_TO_CONNECT_O365_STATES.includes(user.state)) {
      return
    }
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_SIGNIN_SUCCESSFUL, {
      url: window.location.href
    })
  }

  function updateUserFlags(user: User, { dispatch }: any) {
    const queryFlags = queryString.parse(window.location.search)
    if (Object.keys(queryFlags).length === 0) {
      return
    }
    const nextFlags = { ...user.flags, ...queryFlags }
    analyticsLib.setUserFlags(user, nextFlags)
    dispatch(setUserFlags({ email: user.email, flags: nextFlags }))
  }

  setSuccessCallbacks({
    async signin(user: User, thunkApi: any) {
      sendSigninAnalyticsEvent(user, thunkApi)
      updateUserFlags(user, thunkApi)
      await doValidateUser({ user })
    },

    async signinOtp(user: User, thunkApi: any) {
      sendSigninAnalyticsEvent(user, thunkApi)
      updateUserFlags(user, thunkApi)
      await doValidateUser({ user })
    },

    async updateUserState(user: User) {
      await doValidateUser({ user })
    },

    async saveSignupUser(user: User) {
      await doValidateUser({ user, isSignupUser: true })
    },

    async saveProfile(user: User, thunkApi: any) {
      await thunkApi.dispatch(setUser(user as User))
    },

    async domainFraudSignup(user: User, thunkApi: any) {
      await thunkApi.dispatch(setUser(user as User))
    },

    async getUser(user: User, thunkApi: any) {
      const isNewUserData = !thunkApi.getState().user.data.id

      await doValidateUser({
        user,
        accessTokenId: isNewUserData ? accessTokenLib.getAccessTokenFromUrl() : undefined
      })
    },

    async getSentinelDemoUser(user: User, thunkApi: any) {
      if (user) {
        await thunkApi.dispatch(setUser(user))

        // set the user for intercom and mixpanel
        analyticsLib.setUser(user)

        const firstAccessTokenId = accessTokenLib.getAllAccessTokens()[0]?.id

        if (firstAccessTokenId) {
          gotoReportPage({ accessTokenId: firstAccessTokenId })
        }
      }
    },

    async logout() {
      // We need to force the url change in admin app since it doesn't have
      //   regiestered signin_signup route related component in Routes.tsx and
      //   React-Router can't catch anything
      const isAdminPage = window.location.pathname.includes('admin')
      routesConfig.SIGNIN_SIGNUP[isAdminPage ? 'forceGoto' : 'goto']()
    }
  })

  setFailedCallbacks({
    async signinOtp() {
      routesConfig.SIGNIN_SIGNUP.goto()
    },
    // When the refresh token is invalid, we need to ask customer to re-login to get a new fresh token.
    async refreshToken(thunkApi: any) {
      thunkApi.dispatch(logout())
    }
  })
}
