import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError, Auth } from 'global/lib/api/restClient'
import { globalApiRoutes } from 'global/lib/api/apiRoutes'
import { config } from 'global/lib/config'
import { RootState } from 'global/redux/toolkit/store'
import { SignupProfile } from 'global/types/api/signupUser'
import { User } from 'global/types/api/userType'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import * as authLib from 'global/lib/auth/auth'

export interface Signin {
  credentials: Auth
}

export interface SigninOtp {
  code: string
}

export interface Signup {
  credentials: Auth
}

export interface NewSignup {
  password: string
  phone: string
}

export interface DomainFraudSignup {
  email: string
  password: string
  country: string
}

export interface UpdateUserState {
  accessTokenId: string
  expectedState: string
}

export interface SaveSignupUser {
  provider: string
  scanType: string
}

export interface SaveProfile extends SignupProfile {
  provider: string
  scanType: string
  accessTokenId?: string
  accessTokenName?: string
}

export interface Logout {
  isNavbarLogout?: boolean
}

export type SuccessCallback = (user: User, thunkApi: any) => Promise<User | undefined | void>
export interface SuccessCallbacks {
  [key: string]: SuccessCallback
}

export type FailedCallback = (thunkApi: any) => Promise<string | undefined | void>
export interface FailedCallbacks {
  [key: string]: FailedCallback
}

const DEFAULT_COUNTRY_CODE = 'US'
const DEFAULT_NAME = 'N/A'
const DEFAULT_COMPANY = 'Default'
const DEFAULT_ZIP = '95008'
let successCallbacks: SuccessCallbacks
let failedCallbacks: FailedCallbacks

export function setSuccessCallbacks(callbacks: SuccessCallbacks) {
  successCallbacks = callbacks
}

export function setFailedCallbacks(callbacks: FailedCallbacks) {
  failedCallbacks = callbacks
}

function getAuthErrorMessage(e: any, user?: any) {
  const error = validateApiError(e)

  if (error === 'user_create_dpl_check_failure' && user) {
    if (user) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_DPL_CHECK_FAIL, { user })
    }
  }

  return error
}

export const signin = createAsyncThunk('AUTH/signin', async function doSignin(payload: Signin, thunkApi) {
  try {
    const resp = await restClient(globalApiRoutes.SIGNIN, {
      auth: { ...payload.credentials },
      data: { email: payload.credentials.username }
    })

    let successCbValue
    if (successCallbacks.signin) {
      successCbValue = await successCallbacks.signin(resp.data.user, thunkApi)
    }

    return successCbValue || resp.data.user
  } catch (e) {
    const error = getAuthErrorMessage(e)

    let failedCbValue
    if (failedCallbacks.signin) {
      failedCbValue = await failedCallbacks.signin(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const refreshToken = createAsyncThunk('AUTH/refreshToken', async function doRefreshToken(_, thunkApi) {
  try {
    const data = await restClient(globalApiRoutes.REFRESH_TOKEN)

    return data
  } catch (e) {
    const error = getAuthErrorMessage(e)

    await failedCallbacks.refreshToken(thunkApi)

    return thunkApi.rejectWithValue(error)
  }
})

export const signinOtp = createAsyncThunk('AUTH/signinOtp', async function doSigninOtp(payload: SigninOtp, thunkApi) {
  try {
    const resp = await restClient(globalApiRoutes.SIGNIN_OTP, {
      data: { otp: payload.code }
    })

    let successCbValue
    if (successCallbacks.signinOtp) {
      successCbValue = await successCallbacks.signinOtp(resp.data.user, thunkApi)
    }

    return successCbValue || resp.data.user
  } catch (e) {
    const error = getAuthErrorMessage(e)

    let failedCbValue
    if (failedCallbacks.signinOtp) {
      failedCbValue = await failedCallbacks.signinOtp(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const signup = createAsyncThunk('AUTH/signup', async function doSignup(payload: Signup, thunkApi) {
  try {
    const resp = await restClient(globalApiRoutes.SIGNUP, {
      auth: { ...payload.credentials },
      data: { email: payload.credentials.username }
    })

    let successCbValue
    if (successCallbacks.signup) {
      successCbValue = await successCallbacks.signup(resp.data.user, thunkApi)
    }

    return successCbValue || resp.data.user
  } catch (e) {
    let failedCbValue
    const error = getAuthErrorMessage(e)

    if (axios.isAxiosError(e) && e.response?.data?.error === config.API_ERROR.USER_ALREADY_EXISTS) {
      thunkApi.dispatch(signin(payload))
    } else if (failedCallbacks.signup) {
      failedCbValue = await failedCallbacks.signup(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const newSignup = createAsyncThunk('AUTH/newSignup', async function doNewSignup(_: NewSignup, thunkApi) {
  const { user, name: companyName } = (thunkApi.getState() as RootState).auth.signup
  const provider = config.CLOUD_PROVIDERS.office365.id

  try {
    const resp = await restClient(globalApiRoutes.SIGNUP, {
      auth: { username: user.email, password: user.password },
      data: {
        email: user.email,
        provider,
        isNewSignupFlow: true,
        name: user.displayName,
        country: user.country,
        phone: user.phoneNumber,
        company: companyName
      }
    })

    let successCbValue
    if (successCallbacks.newSignup) {
      successCbValue = await successCallbacks.newSignup(resp.data.user, thunkApi)
    }

    return successCbValue || resp.data.user
  } catch (e) {
    const error = getAuthErrorMessage(e, user)

    let failedCbValue
    if (failedCallbacks.newSignup) {
      failedCbValue = await failedCallbacks.newSignup(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const domainFraudSignup = createAsyncThunk('AUTH/domainFraudSignup', async function doDomainFraudSignup(
  payload: DomainFraudSignup,
  thunkApi
) {
  const { password, ...restSignupVaules } = payload
  const provider = config.CLOUD_PROVIDERS.onprem.id

  try {
    const resp = await restClient(globalApiRoutes.SIGNUP, {
      auth: { username: restSignupVaules.email, password },
      data: {
        ...restSignupVaules,
        provider,
        isNewSignupFlow: false
      }
    })

    let successCbValue
    if (successCallbacks.domainFraudSignup) {
      successCbValue = await successCallbacks.domainFraudSignup(resp.data.user, thunkApi)
    }

    return successCbValue || resp.data.user
  } catch (e) {
    const error = getAuthErrorMessage(e)

    let failedCbValue
    if (failedCallbacks.domainFraudSignup) {
      failedCbValue = await failedCallbacks.domainFraudSignup(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const setUserFlags = createAsyncThunk('AUTH/setUserFlags', async function doSetUserFlags(
  signinConfig: { email: string; flags: any } | undefined,
  thunkApi
) {
  const signupConfig = (thunkApi.getState() as RootState).auth.signup
  try {
    const resp = await restClient(globalApiRoutes.SIGNUP_FLAGS, {
      data: {
        email: signinConfig?.email || signupConfig?.user.email,
        flags: JSON.stringify(signinConfig?.flags || signupConfig?.user.flags)
      }
    })

    let successCbValue
    if (successCallbacks.setUserFlags) {
      successCbValue = await successCallbacks.setUserFlags(resp.data.user, thunkApi)
    }

    return successCbValue || resp.data.user
  } catch (e) {
    const error = getAuthErrorMessage(e)

    let failedCbValue
    if (failedCallbacks.setUserFlags) {
      failedCbValue = await failedCallbacks.setUserFlags(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const updateUserState = createAsyncThunk('AUTH/updateUserState', async function doUpdateUserState(
  payload: UpdateUserState,
  thunkApi
) {
  try {
    const resp = await restClient(globalApiRoutes.UPDATE_STATE, {
      data: {
        accessTokenId: payload.accessTokenId,
        expectedState: payload.expectedState
      }
    })
    let successCbValue

    if (successCallbacks.updateUserState) {
      successCbValue = await successCallbacks.updateUserState(resp.data.user, thunkApi)
    }

    return successCbValue || resp.data.user
  } catch (e) {
    const error = getAuthErrorMessage(e)

    let failedCbValue
    if (failedCallbacks.updateUserState) {
      failedCbValue = await failedCallbacks.updateUserState(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const saveSignupUser = createAsyncThunk('AUTH/saveSignupUser', async function doSaveSignupUser(
  payload: SaveSignupUser,
  thunkApi
) {
  const signupConfig = (thunkApi.getState() as RootState).auth.signup

  const userName =
    signupConfig.user.displayName || `${signupConfig.user.firstName || ''} ${signupConfig.user.lastName || ''}`.trim()

  try {
    const resp = await restClient(globalApiRoutes.SIGNUP_SAVE_PROFILE, {
      data: {
        scanType: payload.scanType,
        provider: payload.provider,
        accessTokenId: signupConfig.id,
        accessTokenName: signupConfig.name,
        email: signupConfig.user.email,
        name: userName.length ? userName : DEFAULT_NAME,
        zip: signupConfig.user.zipCode || DEFAULT_ZIP,
        company: signupConfig.user.companyName || signupConfig.name || DEFAULT_COMPANY,
        phone: signupConfig.user.phoneNumber,
        country: signupConfig.user.country || DEFAULT_COUNTRY_CODE
      }
    })

    let successCbValue
    if (successCallbacks.saveSignupUser) {
      successCbValue = await successCallbacks.saveSignupUser(resp.data.user, thunkApi)
    }

    return successCbValue || resp.data.user
  } catch (e) {
    const error = getAuthErrorMessage(e)

    let failedCbValue
    if (failedCallbacks.saveSignupUser) {
      failedCbValue = await failedCallbacks.saveSignupUser(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const saveProfile = createAsyncThunk('AUTH/saveProfile', async function doSaveProfile(
  payload: SaveProfile,
  thunkApi
) {
  try {
    const resp = await restClient(globalApiRoutes.SIGNUP_SAVE_PROFILE, {
      data: { ...payload }
    })

    let successCbValue
    if (successCallbacks.saveProfile) {
      successCbValue = await successCallbacks.saveProfile(resp.data.user, thunkApi)
    }

    return successCbValue || resp.data.user
  } catch (e) {
    const error = getAuthErrorMessage(e)

    let failedCbValue
    if (failedCallbacks.saveProfile) {
      failedCbValue = await failedCallbacks.saveProfile(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const getUser = createAsyncThunk('AUTH/getUser', async function doGetUser(
  isDomainFraudPage: boolean | undefined = false,
  thunkApi
) {
  try {
    const provider = isDomainFraudPage ? config.CLOUD_PROVIDERS.onprem.id : undefined

    const resp = await restClient(globalApiRoutes.GET_USER, {
      data: { provider }
    })

    let successCbValue
    if (successCallbacks.getUser) {
      successCbValue = await successCallbacks.getUser(resp?.data?.user, thunkApi)
    }

    return successCbValue || resp?.data?.user || {}
  } catch (e) {
    const error = getAuthErrorMessage(e)

    let failedCbValue
    if (failedCallbacks.getUser) {
      failedCbValue = await failedCallbacks.getUser(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const getForensicsDemoUser = createAsyncThunk('AUTH/getForensicsDemoUser', async function doGetForensicsDemoUser(
  _,
  thunkApi
) {
  try {
    const resp = await restClient(globalApiRoutes.GET_FORENSICS_DEMO_USER)

    let successCbValue
    if (successCallbacks.getForensicsDemoUser) {
      successCbValue = await successCallbacks.getForensicsDemoUser(resp?.data?.user, thunkApi)
    }

    authLib.initializeUserInServices()

    return successCbValue || resp?.data?.user || {}
  } catch (e) {
    const error = getAuthErrorMessage(e)

    let failedCbValue
    if (failedCallbacks.getForensicsDemoUser) {
      failedCbValue = await failedCallbacks.getForensicsDemoUser(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const getSentinelDemoUser = createAsyncThunk('AUTH/getSentinelDemoUser', async function doGetSentinelDemoUser(
  _,
  thunkApi
) {
  try {
    const resp = await restClient(globalApiRoutes.GET_SENTINEL_DEMO_USER)

    let successCbValue
    if (successCallbacks.getSentinelDemoUser) {
      successCbValue = await successCallbacks.getSentinelDemoUser(resp?.data?.user, thunkApi)
    }

    authLib.initializeUserInServices()

    return successCbValue || resp?.data?.user || {}
  } catch (e) {
    const error = getAuthErrorMessage(e)

    let failedCbValue
    if (failedCallbacks.getSentinelDemoUser) {
      failedCbValue = await failedCallbacks.getSentinelDemoUser(thunkApi)
    }

    return thunkApi.rejectWithValue(failedCbValue || error)
  }
})

export const logout = createAsyncThunk('AUTH/logout', async function doLogout(
  isNavbarLogout: boolean | undefined = false,
  thunkApi
) {
  const { accessTokenId } = (thunkApi.getState() as RootState).auth.logoutValues || {}

  try {
    if (isNavbarLogout) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_LOGOUT, { accessTokenId })
    }

    const resp = await restClient(globalApiRoutes.LOGOUT)

    if (successCallbacks.logout) {
      await successCallbacks.logout({} as User, thunkApi)
    }

    // Do not open the BCC logout in new tab see BNFIR-3598
    window.open(config.bccLogoutUrl, '_self')

    return resp
  } catch (e) {
    const error = getAuthErrorMessage(e)

    return thunkApi.rejectWithValue(error)
  }
})

export const activateDeveloperInterface = createAsyncThunk(
  'AUTH/activateDeveloperInterface',
  async function doActivateDeveloperInterface() {
    await restClient(globalApiRoutes.ACTIVATE_DEVELOPER_INTERFACE)
  }
)
