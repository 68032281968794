import globalMessages, {
  CLOSE,
  SIGN_IN,
  SIGNIN_TITLE,
  FORGOT_MY_PASSWORD,
  SEARCH,
  BACK,
  CANCEL,
  MICROSOFT_365
} from 'global/lib/i18n/en'

/* eslint-disable @typescript-eslint/naming-convention, quotes */
export const START_TRIAL = 'Start a free trial'
export const EMAILS_SCANNED = 'Emails scanned'
export const THREATS_DETECTED = 'Threats detected'
export const THREATS_FOUND_DESCRIPTION =
  "Total threat emails found across all of your employees' inboxes in the past 12 months."
export const EMPLOYEES_WITH_THREATS = 'Employees with threats'
export const THREAT_TYPES_FOUND = 'Threat types found'
export const DOMAIN_DMARC_STATUS = 'Domain DMARC status'
export const DOMAIN_DMARC_STATUS_DESCRIPTION =
  'DMARC is an email authentication protocol that enables domain owners to protect their domains from unauthorized use, also known as email spoofing.'
export const PAGE = 'Page'
export const ALL_EMPLOYEES = 'All employees'
export const HIGH_RISK_EMPLOYEES = 'High-risk employees'
export const MEDIUM_RISK_EMPLOYEES = 'Medium-risk employees'
export const LOW_RISK_EMPLOYEES = 'Low-risk employees'
export const DOMAIN = 'Domain'
export const QUARANTINE = 'Quarantine'
export const REJECT = 'Reject'
export const SOURCES_OF_ATTACKS = 'Sources of attacks'
export const EMAILS_WITH_THREATS = 'Emails with threats'
export const PERMISSION_DIALOG_TITLE = 'Why are so many permissions needed to run the free Barracuda Email Threat Scan?'
export const BARRACUDA_ETS = 'Barracuda Email Threat Scanner'
export const ETS = 'Email Threat Scanner'
export const DETAILS_UNAVAILABLE = 'Details not available for scans older than 90 days'

export default {
  ...globalMessages,
  ets: {
    error: {
      ...globalMessages.app.error
    },
    my_link: {
      heading_title: `${BARRACUDA_ETS} is a tool that can help you sell more.`,
      heading_subtitle: `Your customers can scan their ${MICROSOFT_365} accounts and get a
      personalized report on advanced threats that are already in their
      account. Signing up for a scan takes 2 minutes and it's 100% free.`,
      title: 'Get your personalized link',
      email_heading: '1. Enter your email',
      email: 'Email',
      link_heading: `2. Copy this personal ${ETS} sign up link`,
      copied: 'Copied',
      share_description: `3. Share this link with all your ${MICROSOFT_365} customers to help them scan their accounts for advanced threats.`,
      footer: 'To track scans from your personalized link, visit ',
      footer_link: 'How many scans?'
    },
    signin_signup: {
      app_name: BARRACUDA_ETS,
      info: {
        accordion_1_title: 'Get your personalized threat report',
        accordion_1_intro: `Why should I use the ${BARRACUDA_ETS}?`,
        accordion_2_title: 'Why do we need permission to access emails and user information?',
        accordion_2_intro:
          'We need these permissions to map the historical communication patterns in the organization to detect and block employee impersonation, spear phishing and business email compromise.',
        accordion_2_list_1: "Read and write all users' full profiles",
        accordion_2_list_2: 'Read and write mail in all mailboxes',
        accordion_2_list_3: 'Access directory as the signed in user',
        accordion_2_list_4: 'Read directory data',
        accordion_2_list_5: 'Read and write directory data',
        accordion_2_list_6: 'Read and write files in all site collections',
        accordion_2_list_7: 'Read DLP policy events including detected sensitive data',
        accordion_3_title: 'Why do we need permission to access security and risk information?',
        accordion_3_intro: 'We need this permission to detect account takeover events and remediate them.',
        accordion_3_list_1: 'Read all identity risk event information',
        accordion_3_list_2: 'Read all audit log data',
        accordion_3_list_3: "Read your organization's security events",
        accordion_3_list_4: 'Read DLP policy events including detected sensitive data',
        accordion_3_list_5: 'Read activity data for your organization',
        accordion_3_list_6: 'Read service health information for your organization',
        accordion_3_list_7: 'Read and write all user mailbox settings',
        accordion_3_list_8: 'Read all groups',
        accordion_3_list_9: 'Read and write devices',
        accordion_4_title: 'Why do we need permission to send mail as any user?',
        accordion_4_intro:
          'We need these permissions to send a warning notice to external senders after a compromised account is detected.',
        accordion_4_list_1: 'Send mail as any user',
        title: 'Run the free email threat scan.',
        title_new: 'Run a free\nemail threat scan.',
        subtitle: "Get {freeScans} free scans. It's fast and easy.",
        headertext: `The ${BARRACUDA_ETS} (ETS) inspects emails in your organization to discover threats hiding in your employees' inboxes.`,
        headertext_new: `The ${BARRACUDA_ETS} inspects emails in your organization to discover threats hiding in your employees' inboxes.`,
        find_threats_main: "Find threats already in your users' inboxes",
        find_threats_list_1: 'Spear phishing',
        find_threats_list_2: 'Business email compromise',
        find_threats_list_3: 'Conversation hijacking',
        find_threats_list_4: 'Brand and domain impersonation',
        find_threats_list_5: 'Extortion',
        find_threats_list_6: 'Scamming/Graymail',
        find_threats_list_7: 'URL phishing',
        organizations_ran: 'organizations ran<b>Email Threat Scan</b>',
        mailboxes_scanned: 'mailboxes<b>scanned</b>',
        spear_phishing: 'spear phishing<b>attacks identified</b>',
        watch_video: 'Watch video to learn more',
        speak_to_expert: 'Speak to an expert'
      },
      form: {
        permission_dialog_title: PERMISSION_DIALOG_TITLE,
        forgot_my_password: FORGOT_MY_PASSWORD,
        sign_in: SIGNIN_TITLE,
        sign_in_button: SIGN_IN,
        already_have_account: 'Already have account? <link>Sign in</link>',
        already_have_account_2: '<link>Already have a Barracuda account? <b>Sign in</b></link>',
        do_not_have_account: "Don't have a Barracuda account? <link>Sign up</link>"
      }
    },
    signup_complete: {
      title: 'Complete your Barracuda account',
      phone_number: 'Phone number',
      agree_privacy_policy: 'I agree to the <a>Barracuda Networks privacy policy.</a>',
      scan_now: 'Scan now',
      placeholders: {
        password: 'minimum {char} characters',
        confirm_password: 'confirm password'
      },
      form_errors: {
        weak_password:
          'Your password needs to have: <li>- 8 characters</li><li>- 1 capital letter</li><li>- 1 digit</li>',
        password_match: "The password doesn't match",
        invalid_phone: 'Invalid phone number'
      }
    },
    signup_complete_policy: {
      title: 'Barracuda Networks privacy policy'
    },
    signin_connect: {
      app_name: BARRACUDA_ETS,
      title: `Connect to ${MICROSOFT_365} to start Email Threat Scan`,
      permission_dialog_title: PERMISSION_DIALOG_TITLE,
      subtitle_1: `${MICROSOFT_365} admin account is required.`,
      subtitle_2:
        'Learn what <perm>permissions</perm> are needed. If you are not an admin - <demo>request a demo.</demo>',
      o365_button: `Connect to ${MICROSOFT_365}`,
      footer_text: 'Use another account',
      account_want_to_connect: 'Account to connect'
    },
    risk_levels: {
      high_risk: 'High',
      medium_risk: 'Medium',
      low_risk: 'Low'
    },
    high_risk_factors: {
      inv: 'Manages invoices',
      vip: 'Holds executive position',
      wt: 'Initiates wire transfers',
      hr: 'Accesses HR information'
    },
    dashboard: {
      header: {
        new_scan: 'New scan',
        delete_scan: 'Delete report',
        share: 'Share',
        share_link: 'Share link',
        pdf_export: 'Export as PDF',
        share_expires: 'Link expires {expiration}',
        scan_status: 'Scan status',
        scan_preparing: 'Preparing for scan',
        scan_in_progress: 'Scan in progress',
        scan_completed: 'Scan completed',
        started_on: 'Started on',
        completed_on: 'Completed on',
        time_remaining: 'Time remaining',
        scan_duration: 'Scan duration',
        emails_scanned: EMAILS_SCANNED,
        threats_detected: THREATS_DETECTED,
        threat_emails_found: 'Threat emails found',
        no_threats_detected: 'No threats detected yet...',
        switch: {
          enabled: 'Enabled',
          disabled: 'Disabled'
        },
        copy_to_clipboard: 'Copy to clipboard',
        copy: 'Copy',
        copied: 'Copied',
        scan_estimate_text: 'Most scans complete within 24 hours'
      },
      banner: {
        text: 'Protect your organization from email threats. Start a free trial now.'
      },
      menu: {
        tabs: {
          overview: 'Overview',
          employees: 'Employees',
          threats: 'Threats',
          domains: 'Domains',
          attacks: SOURCES_OF_ATTACKS
        }
      },
      in_progress: {
        title: 'Sample data,<br></br>report will be updated once scan is complete.'
      },
      overview: {
        title: 'Your scan summary',
        details: 'Details',
        total_threats_found: {
          title: 'Threats found',
          description: THREATS_FOUND_DESCRIPTION
        },
        employees_with_threats: {
          title: EMPLOYEES_WITH_THREATS,
          description: 'Total unique employees who received at least one threat email in the past 12 months.'
        },
        threat_types_found: {
          title: THREAT_TYPES_FOUND,
          description:
            'Total email threat types found in the past 12 months.<br></br>Hover over each threat type for more information.',
          subtitle: 'What is {threatType}?'
        },
        domain_dmarc_status: {
          title: DOMAIN_DMARC_STATUS,
          description: DOMAIN_DMARC_STATUS_DESCRIPTION,
          not_configured: '<b>Not Configured</b><br></br>Domain can be spoofed or used for fraud',
          reporting_mode: '<b>Reporting Mode</b><br></br>Domain fraud is reported, but is not enforced',
          enforcement_mode: '<b>Enforcement Mode</b><br></br>Domains protected with DMARC enforcement'
        }
      },
      employees: {
        title: 'A look at your employees',
        tabs: {
          all_employees: ALL_EMPLOYEES,
          high_risk: HIGH_RISK_EMPLOYEES,
          medium_risk: MEDIUM_RISK_EMPLOYEES,
          low_risk: LOW_RISK_EMPLOYEES
        },
        table_total: 'Employees ({total})',
        search_field_placeholder: SEARCH,
        see_details: 'View details',
        empty_table: 'No employees found',
        button: BACK
      },
      attacking_domains: {
        table_total: 'Attacking domains ({total})',
        search_field_placeholder: SEARCH,
        see_details: 'View details',
        empty_table: 'No attacking domains found',
        button: BACK
      },
      user_emails_dialog: {
        title_username: 'Attacks received by {userName}',
        title_domain: 'Attacks from {domain}',
        close: CLOSE,
        see_details: 'View email',
        details_unavailable: DETAILS_UNAVAILABLE
      },
      threats: {
        title: 'Threats to your security',
        table_total: 'Threats ({total})',
        search_field_placeholder: SEARCH,
        empty_table: 'No threats found',
        see_details: 'View email',
        details_unavailable: DETAILS_UNAVAILABLE,
        button: 'Dismiss'
      },
      domains: {
        title: 'DMARC protection status of your domains',
        table_total: 'Domains ({total})',
        search_field_placeholder: SEARCH,
        empty_table: 'No domains found',
        see_details: 'View details',
        quarantine: QUARANTINE,
        reject: REJECT,
        dmarc_states: {
          unprotected:
            '<b>Not configured</b><br></br>Domains can be spoofed or used for fraud. Consider protecting your domains.',
          misconfigured: '<b>Misconfigured</b><br></br>Fraud data monitoring misconfigured',
          reporting:
            "<b>Reporting mode</b><br></br>Domain fraud is reported, but it's not enforced. Consider changing to Enforcement Mode to actively protect your domains.",
          protected:
            '<b>Enforcement mode - {policy}</b><br></br>Emails from your domain that fail DMARC will be rejected by organizations that observe DMARC. Be sure to regularly monitor your DMARC records.'
        }
      },
      attacks: {
        title: 'Sources of attacks'
      }
    },
    data_tables: {
      threat_log: {
        received: 'Received',
        employee: 'Employee',
        from: 'From',
        subject: 'Subject',
        attack_types: 'Attack types'
      },
      employees: {
        name: 'Name',
        email: 'Email',
        title: 'Title',
        risk_level: 'Risk level',
        high_risk_factors: 'High-risk factors',
        threats_found: 'Threats found',
        action: 'Action'
      },
      threats: {
        received: 'Received',
        recipients: 'Recipients',
        sample_recipient: 'Sample recipient',
        email: 'Email',
        attack_type: 'Attack type',
        action: 'Action'
      },
      user_emails: {
        received: 'Received',
        recipient: 'Recipient',
        email: 'Email',
        action: 'Action'
      },
      domains: {
        domain: DOMAIN,
        status: 'Status',
        action: 'Action'
      },
      attacking_domains: {
        domain: DOMAIN,
        emails_with_threats: EMAILS_WITH_THREATS,
        action: 'Action'
      }
    },
    dialogs: {
      dmarc: {
        title: 'Set up strong email authentication with domain fraud protection',
        description:
          'DMARC (Domain-based Message Authentication, Reporting, and Conformance) is a protocol that helps to protect your domain from spoofing. DMARC determines the authenticity of an email message using SPF (Sender Policy Framework) and DKIM (DomainKeys Identified Mail).',
        your_dmarc_entry: 'Your DMARC entry:',
        dmarc_not_configured:
          'Your domain does not have DMARC configured, so it is vulnerable to phishing attacks and fraud schemes.',
        dmarc_not_valid:
          'Your domain does not have DMARC configured properly, so it is vulnerable to phishing attacks and other malicious fraud schemes.',
        none_setting:
          'The <b>none</b> setting tells the receiving server not to perform any actions against unqualified mail from your domain. If, however, it receives any unqualified mail from your domain, the receiving server should send an email report to the mailto: address in the DMARC record.',
        reject_setting:
          'The <b>reject</b> setting tells the receiving server to completely deny any unqualified mail from your domain. With the <b>reject</b> setting, only mail that is verified as signed by your domain can possibly be delivered to the recipient. Any mail that does not pass is denied – not bounced – so it is not possible to catch false positives.',
        quarantine_setting:
          'The <b>quarantine</b> setting tells the receiving server to quarantine any unqualified mail from your domain. This is usually accomplished by sending those messages directly to the junk folder.',
        policy:
          '<b>• DMARC record for your domain:</b> published<br></br><b>• DMARC policy setting:</b> {policy} (p={policy})',
        start_a_free_trial:
          'Start a FREE trial of Impersonation Protection to set up strong email authentication, including enabling DMARC enforcement, to prevent future attacks.',
        close: CLOSE,
        button: START_TRIAL
      },
      free_trial: {
        button: START_TRIAL,
        title: 'Welcome to your 14-Day free trial of Impersonation Protection',
        description: 'Choose how you want to handle spear phishing attacks and other fraud.',
        activate: 'Activate',
        close: CLOSE,
        use_another_account: 'Use another account',
        app_name: 'Impersonation Protection'
      },
      delete_report: {
        title: 'Delete this report',
        description: `If you choose to delete your report, be aware that:<br></br><br></br>• This action cannot be undone.<br></br><br></br>• You must reconnect your ${MICROSOFT_365} account to run future scans.`,
        delete: 'Delete',
        cancel: CANCEL
      },
      new_scan: {
        title: 'Start a new scan?',
        description:
          'This will start a new scan for <b>{name}</b>. Your previous report is saved and accessible via the dropdown list in the menu on the top.',
        start: 'Start new scan',
        cancel: CANCEL
      }
    },
    empty_report: {
      top_title: `Your Barracuda account is not connected to any ${MICROSOFT_365} admin account.`,
      bottom_title: `If you don't have access to an ${MICROSOFT_365} admin account, please reach out to your organization's admin.`,
      connect_to_o365: `Connect to ${MICROSOFT_365} account`
    },
    pdf: {
      cover: {
        title: 'Table of contents',
        page: PAGE,
        page_1_title: 'Your scan summary',
        page_2_title: 'A look at your employees',
        page_3_title: 'Threats to your security',
        page_4_title: 'DMARC protection status of your domains',
        page_5_title: 'Sources of attacks'
      },
      summary: {
        title: 'Your scan summary',
        completed_on: 'Scan completed on',
        duration: 'Duration',
        emails_scanned: EMAILS_SCANNED,
        threats_detected: THREATS_DETECTED,
        threats_found: 'Total threats found',
        threats_found_description: THREATS_FOUND_DESCRIPTION,
        employees_with_threats: EMPLOYEES_WITH_THREATS,
        employees_with_threats_description:
          'Number of employees who have threat emails found in their inboxes in the past 12 months.',
        threat_types_found: THREAT_TYPES_FOUND,
        threat_types_found_description: 'Total email threat types found in the past 12 months.',
        domain_dmarc_status: DOMAIN_DMARC_STATUS,
        domain_dmarc_status_description: DOMAIN_DMARC_STATUS_DESCRIPTION,
        dmarc: {
          not_configured: 'Not configured',
          not_configured_description: 'Domain can be spoofed or used for fraud',
          reporting_mode: 'Reporting mode',
          reporting_mode_description: 'Domain fraud is reported, but is not enforced',
          enforcement_mode: 'Enforcement mode',
          enforcement_mode_description: 'Domains protected with DMARC enforcement'
        }
      },
      employees: {
        title: 'A look at your employees',
        all_employees: ALL_EMPLOYEES,
        high_risk: HIGH_RISK_EMPLOYEES,
        medium_risk: MEDIUM_RISK_EMPLOYEES,
        low_risk: LOW_RISK_EMPLOYEES,
        subtitle: 'Top {count}at-risk employees',
        table: {
          name: 'Employee name',
          email: 'Employee email',
          job_title: 'job title',
          risk_level: 'Risk level',
          factors: 'High-risk factors',
          threats_found: 'Threats found'
        },
        empty_table: 'No employees found'
      },
      threats: {
        title: 'Threats to your security',
        subtitle: 'Top {count}threats received',
        empty_table: 'No threats found'
      },
      domains: {
        title: 'DMARC protection status of your domains',
        subtitle: 'Top {count}domains',
        description:
          "Domain-based Message Authentication, Reporting and Conformance (DMARC) is an email-validation system designed to detect and prevent email spoofing. It can be used to defend against certain types of emails attacks, including phishing and email spam. In these types of attacks, the email sender's address is forged, but the email itself appears to be legitimate. DMARC attempts to counter the illegitimate usage of the exact domain name in the From field of email message headers. If you have DMARC enabled and other organizations are recognizing DMARC, then your domain cannot be spoofed in phishing attempts to those recipients, thereby protecting the reputation of your domain.",
        table: {
          domain: DOMAIN,
          dmarc_status: 'DMARC Status'
        },
        dmarc_states: {
          unprotected: {
            name: 'Not configured',
            description: 'Domains can be spoofed or used for fraud. Consider protecting your domains.'
          },
          misconfigured: {
            name: 'Misconfigured',
            description: 'Fraud data monitoring misconfigured'
          },
          reporting: {
            name: 'Reporting mode',
            description:
              "Domain fraud is reported, but it's not enforced. Consider changing to Enforcement Mode to actively protect your domains."
          },
          protected: {
            name: 'Enforcement mode - {policy}',
            description:
              'Emails from your domain that fail DMARC will be rejected by organizations that observe DMARC. Be sure to regularly monitor your DMARC records.'
          }
        },
        quarantine: QUARANTINE,
        reject: REJECT,
        empty_table: 'No domains found'
      },
      attacks: {
        title: SOURCES_OF_ATTACKS,
        subtitle: 'Top {count}attacking domains',
        table: {
          domain: 'Attacking domain',
          emails_with_threats: EMAILS_WITH_THREATS
        },
        empty_table: 'No attacking domains found'
      },
      footer: {
        for_detailed_report: 'View report',
        page: PAGE
      }
    }
  }
}
/* eslint-enable @typescript-eslint/naming-convention, quotes */
