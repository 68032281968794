import React, { useMemo } from 'react'
import { DateTime } from 'luxon'

import {
  MaterialReactTable,
  MRT_ColumnDef as MRTColumnDef,
  MRT_SortingState as MRTSortingState,
  MRT_ColumnFiltersState as MRTColumnFiltersState,
  MRT_ExpandedState as MRTExpandedState
} from 'material-react-table'

import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'

import { ViewColumn } from '@material-ui/icons'

import BDSTheme from '@barracuda-internal/bds-core/dist/styles/themes/index'
import { Button, Chip, Drawer, Grid, MenuItem, TextField, Typography } from '@barracuda-internal/bds-core'
import { Close, Filter } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { DateRangePicker } from 'global/lib/bitDateRangePicker'

import SearchField, { SearchFieldProps } from 'global/components/lib/searchField/SearchField'
import SideMenu, { Dropdown } from 'global/components/lib/sideMenu/SideMenu'
import Pagination from 'global/components/lib/pagination/Pagination'
import { useFormatMessage } from 'global/lib/localization'

import styles from './dataTableStyles'
import useDataTableLogic from './useDataTableLogic'

export interface ColumnConfig {
  columns: MRTColumnDef<any>[]
  columnMenuItems: Dropdown[]
  columnMenuTitle: string
  columnMenuSubtitle?: string
  columnVisibility: {
    [key: string]: boolean
  }
  handleColumnMenuFilterChange: (search: string) => void
}

export interface GroupConfig {
  enableColumnDragging?: boolean
  enablePinning?: boolean
  enableGrouping?: boolean
  grouping: string[]
  onGroupingChange?: (grouping: any) => void
  renderDetailPanel?: (row: any) => React.ReactNode
}

export interface FilterConfig {
  columnFilters: MRTColumnFiltersState
  columnFilterChips: { id: string; value: string; condition: string }[]
  onFilterChange?: (filters: MRTColumnFiltersState) => void
  enableGlobalFilter?: boolean
  enableColumnFilters?: boolean
  handleClearColumnFilters: () => void
  handleRemoveColumnFilter: (columnId: string) => void
}

export interface DisplayTopResultsConfig {
  onDisplayCountChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  menuItems: Record<string, unknown>[]
  value: number | undefined
  label: string
}

export interface ExpandConfig {
  enableExpanding?: boolean
  enableExpandAll?: boolean
  expanded: MRTExpandedState
  onExpandChange: (expanded: any) => void
  positionExpandColumn?: 'first' | 'last'
}

export interface RowActionsConfig {
  renderRowActions?: (row: any) => React.ReactNode
  enableRowActions?: boolean
  positionActionsColumn?: 'first' | 'last'
}

export interface PageConfig {
  onPageChange: (page: any) => void
  onItemsPerPageChange: (itemsPerPage: any) => void
  skip: number
  take: number
  total: number
}

export interface SortConfig {
  sorting: MRTSortingState
  setSorting: React.Dispatch<React.SetStateAction<any[]>>
}

export interface SearchConfig {
  searchFieldConfig: SearchFieldProps
  searchPlaceholder: string
}

export interface DateRangeConfig {
  dateRange?: [DateTime, DateTime]
  label?: string
  maxDate?: DateTime
  minDate?: DateTime
  selectedShortcutLabel?: string
  error: boolean
  errorMessage: string
  onDateRangeChange: (dateRange: [DateTime, DateTime], ctx: any) => void
}

export interface DataTableProps {
  columnConfig: ColumnConfig
  data: any[]
  dateRangeConfig: DateRangeConfig
  displayTopResultsConfig: DisplayTopResultsConfig
  expandConfig: ExpandConfig
  filterConfig: FilterConfig
  groupConfig: GroupConfig
  isLoading: boolean
  pageConfig: PageConfig
  rowActionsConfig: RowActionsConfig
  searchConfig: SearchConfig
  sortConfig: SortConfig
}

const BASE_I18N_KEY = 'app.unified_reporting'

const DataTable: React.FC<DataTableProps> = ({
  columnConfig,
  data,
  dateRangeConfig,
  displayTopResultsConfig,
  expandConfig,
  filterConfig,
  groupConfig,
  isLoading,
  pageConfig,
  rowActionsConfig,
  searchConfig,
  sortConfig
}) => {
  const classes = styles()

  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [dataTableLogic] = useDataTableLogic({
    filterConfig,
    groupConfig
  })

  const {
    handleClearColumnFilters,
    handleRemoveColumnFilter,
    isColumnVisibilityOpen,
    toggleColumnVisibilityModal
  } = dataTableLogic

  return useMemo(
    () => (
      <>
        <Grid item xs={12}>
          <Grid container direction="row" className={classes.topBarActions}>
            <SearchField
              {...searchConfig.searchFieldConfig}
              searchContainerClassName={classes.searchContainer}
              placeholder={searchConfig.searchPlaceholder}
              iconSize="small"
            />
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateRangePicker
                value={dateRangeConfig.dateRange}
                onChange={dateRangeConfig.onDateRangeChange}
                singleInput
                label={dateRangeConfig.label}
                maxDate={dateRangeConfig.maxDate}
                minDate={dateRangeConfig.minDate}
                selectedShortcutLabel={dateRangeConfig.selectedShortcutLabel}
                error={dateRangeConfig.error}
                errorMessage={dateRangeConfig.errorMessage}
                shortcutsItems={[
                  {
                    label: formatMessage('date_range_picker.last_24_hours'),
                    getValue: () => {
                      const today = DateTime.now()
                      return [today.minus({ days: 1 }), today]
                    }
                  },
                  {
                    label: formatMessage('date_range_picker.last_7_days'),
                    getValue: () => {
                      const today = DateTime.now()
                      return [today.minus({ days: 7 }), today]
                    }
                  },
                  {
                    label: formatMessage('date_range_picker.last_30_days'),
                    getValue: () => {
                      const today = DateTime.now()
                      return [today.minus({ days: 30 }), today]
                    }
                  },
                  {
                    label: formatMessage('date_range_picker.last_90_days'),
                    getValue: () => {
                      const today = DateTime.now()
                      return [today.minus({ days: 90 }), today]
                    }
                  },
                  {
                    label: formatMessage('date_range_picker.last_6_months'),
                    getValue: () => {
                      const today = DateTime.now()
                      return [today.minus({ days: 180 }), today]
                    }
                  },
                  { label: 'Reset', getValue: () => [null, null] }
                ]}
              />
            </LocalizationProvider>
            {groupConfig.grouping.length > 0 && (
              <TextField
                InputProps={{ className: classes.displayTopResultsInput }}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    getContentAnchorEl: null
                  }
                }}
                value={displayTopResultsConfig.value}
                onChange={displayTopResultsConfig.onDisplayCountChange}
                select
                label={displayTopResultsConfig.label}
                size="medium"
                defaultValue={displayTopResultsConfig.value}
              >
                {displayTopResultsConfig.menuItems.map((item: any) => {
                  return (
                    <MenuItem key={item.value} value={item.value} className={classes.displayTopResultsMenu}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            )}
            <Button
              className={classes.columnVisibilityButton}
              variant="outlined"
              color="secondary"
              size="medium"
              startIcon={<ViewColumn />}
              onClick={toggleColumnVisibilityModal}
            >
              <Typography variant="body2" className={classes.editColumnsText}>
                {formatMessage('buttons.edit_columns')}
              </Typography>
            </Button>
            <Drawer
              anchor="right"
              open={isColumnVisibilityOpen}
              onClose={toggleColumnVisibilityModal}
              className={classes.drawer}
              PaperProps={{ className: classes.drawerPaper }}
            >
              <Close onClick={toggleColumnVisibilityModal} className={classes.drawerClose} />
              <SideMenu
                dropdownItems={columnConfig.columnMenuItems}
                onSearchChange={columnConfig.handleColumnMenuFilterChange}
                title={columnConfig.columnMenuTitle}
                subtitle={columnConfig.columnMenuSubtitle}
              />
            </Drawer>
          </Grid>
          <Grid item xs={12}>
            {filterConfig.columnFilterChips.length > 0 && (
              <Grid item xs={12} className={classes.tagInput}>
                {filterConfig.columnFilterChips.map(filterChip => (
                  <Chip
                    key={`${filterChip.id}-${filterChip.value}`}
                    className={classes.tag}
                    size="small"
                    label={
                      <>
                        {columnConfig.columns.find(column => column.accessorKey === filterChip.id)?.header}:&nbsp;
                        <strong>{filterChip.value}</strong>
                      </>
                    }
                    onDelete={() => handleRemoveColumnFilter(filterChip.id)}
                  />
                ))}
                <Button variant="text" color="primary" onClick={handleClearColumnFilters}>
                  Clear all
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <MaterialReactTable
          data={data}
          columns={columnConfig.columns}
          // Grouping
          enableGrouping={groupConfig.enableGrouping}
          enableColumnDragging={groupConfig.enableColumnDragging}
          onGroupingChange={groupConfig.onGroupingChange}
          enablePinning={groupConfig.enablePinning}
          renderDetailPanel={groupConfig.renderDetailPanel}
          // Expanding
          enableExpanding={expandConfig.enableExpanding && groupConfig.grouping.length >= 1}
          enableExpandAll={expandConfig.enableExpandAll && groupConfig.grouping.length >= 1}
          positionExpandColumn={expandConfig.positionExpandColumn}
          onExpandedChange={expandConfig.onExpandChange}
          // Filtering
          manualFiltering
          enableGlobalFilter={filterConfig.enableGlobalFilter}
          enableColumnFilters={filterConfig.enableColumnFilters}
          // Sorting
          manualSorting
          onSortingChange={sortConfig.setSorting}
          // Actions
          enableRowActions={rowActionsConfig.enableRowActions}
          renderRowActions={rowActionsConfig.renderRowActions}
          positionActionsColumn={rowActionsConfig.positionActionsColumn}
          // Styling
          enableStickyHeader
          // - subtract 320px for the top bar and app bar
          muiTableContainerProps={{ sx: { height: 'calc(100vh - 320px)' } }}
          muiTableProps={{ sx: { borderBottom: 'none' } }}
          displayColumnDefOptions={{
            'mrt-row-expand': {
              size: 10
            },
            'mrt-row-actions': {
              header: '',
              size: 150
            }
          }}
          muiTableBodyCellProps={({ column, row }) => ({
            sx: {
              backgroundColor:
                column.getIsSorted() || row.getIsGrouped()
                  ? BDSTheme.palette.background.default
                  : BDSTheme.palette.background.paper
            }
          })}
          muiTableHeadCellProps={({ column }) => ({
            sx: {
              backgroundColor: column.getIsSorted()
                ? BDSTheme.palette.background.default
                : BDSTheme.palette.background.paper,
              '& .Mui-TableHeadCell-Content': {
                justifyContent: 'space-between',
                alignItems: 'start'
              }
            }
          })}
          muiTopToolbarProps={() => ({
            sx: {
              backgroundColor: '#E4F4FF',
              '& .MuiBox-root': {
                padding: '0px'
              },
              '& .MuiAlert-message': {
                padding: '16px'
              }
            }
          })}
          muiTablePaperProps={() => ({
            sx: {
              boxShadow: 'none'
            }
          })}
          // Icons
          icons={{
            MoreVertIcon: (props: any) => <Filter {...props} />
          }}
          // Render Custom
          manualPagination
          renderBottomToolbar={() => (
            <Pagination
              onPageChange={pageConfig.onPageChange}
              onItemsPerPageChange={pageConfig.onItemsPerPageChange}
              skip={pageConfig.skip}
              take={pageConfig.take}
              total={pageConfig.total}
            />
          )}
          renderTopToolbarCustomActions={() =>
            groupConfig.grouping.length <= 0 && (
              <Grid item xs={12} className={classes.topBarDropzone}>
                <Typography component="span" variant="body1">
                  Drag a column header and drop it here to group by that column
                </Typography>
              </Grid>
            )
          }
          // Hide custom actions
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableGlobalFilterModes={false}
          enableFilters={false}
          enableHiding={false}
          // State
          initialState={{
            density: 'compact'
          }}
          state={{
            pagination: {
              pageSize: pageConfig.take,
              pageIndex: pageConfig.skip / pageConfig.take
            },
            isLoading,
            sorting: sortConfig.sorting,
            grouping: groupConfig.grouping,
            expanded: expandConfig.expanded,
            columnFilters: filterConfig.columnFilters,
            globalFilter: searchConfig.searchFieldConfig.value,
            columnVisibility: columnConfig.columnVisibility
          }}
        />
      </>
    ),
    [
      classes.columnVisibilityButton,
      classes.displayTopResultsInput,
      classes.displayTopResultsMenu,
      classes.drawer,
      classes.drawerClose,
      classes.drawerPaper,
      classes.editColumnsText,
      classes.searchContainer,
      classes.tag,
      classes.tagInput,
      classes.topBarActions,
      classes.topBarDropzone,
      columnConfig.columnMenuItems,
      columnConfig.columnMenuSubtitle,
      columnConfig.columnMenuTitle,
      columnConfig.columnVisibility,
      columnConfig.columns,
      columnConfig.handleColumnMenuFilterChange,
      data,
      dateRangeConfig.dateRange,
      dateRangeConfig.error,
      dateRangeConfig.errorMessage,
      dateRangeConfig.label,
      dateRangeConfig.maxDate,
      dateRangeConfig.minDate,
      dateRangeConfig.onDateRangeChange,
      dateRangeConfig.selectedShortcutLabel,
      displayTopResultsConfig.label,
      displayTopResultsConfig.menuItems,
      displayTopResultsConfig.onDisplayCountChange,
      displayTopResultsConfig.value,
      expandConfig.enableExpandAll,
      expandConfig.enableExpanding,
      expandConfig.expanded,
      expandConfig.onExpandChange,
      expandConfig.positionExpandColumn,
      formatMessage,
      filterConfig.columnFilterChips,
      filterConfig.columnFilters,
      filterConfig.enableColumnFilters,
      filterConfig.enableGlobalFilter,
      groupConfig.enableColumnDragging,
      groupConfig.enableGrouping,
      groupConfig.enablePinning,
      groupConfig.grouping,
      groupConfig.onGroupingChange,
      groupConfig.renderDetailPanel,
      handleClearColumnFilters,
      handleRemoveColumnFilter,
      isColumnVisibilityOpen,
      isLoading,
      pageConfig.onItemsPerPageChange,
      pageConfig.onPageChange,
      pageConfig.skip,
      pageConfig.take,
      pageConfig.total,
      rowActionsConfig.enableRowActions,
      rowActionsConfig.positionActionsColumn,
      rowActionsConfig.renderRowActions,
      searchConfig.searchFieldConfig,
      searchConfig.searchPlaceholder,
      sortConfig.setSorting,
      sortConfig.sorting,
      toggleColumnVisibilityModal
    ]
  )
}

export default DataTable
