import React, { useMemo } from 'react'
import { BarChart as BarChartIcon, ShowChart as ShowChartIcon, TableChart as TableChartIcon } from '@material-ui/icons'
import ConstructionIcon from '@mui/icons-material/Construction'
import { upperFirst } from 'lodash'

import { DropDownArrow } from '@barracuda-internal/bds-core/dist/Icons/Controls'
import { Button, Grid, Menu, MenuItem, BitBarChart, BitLineChart } from '@barracuda-internal/bds-core'

import IMAGES from 'global/configs/theme/images.config'
import ChartIconWrapper from 'global/components/lib/chartsV2/chartWrapper/ChartIconWrapper'
import {
  ChartConfig,
  MultiSeriesDataChartConfig,
  SingleSeriesDataChartConfig
} from 'global/components/lib/chartsV2/ChartConfig'
import { useFormatMessage } from 'global/lib/localization'

import useChartWrapperLogic from './useChartWrapperLogic'
import styles from './chartWrapperStyles'

const CHART_TOOLTIP_I18N_KEY = 'app.unified_reporting.chart.tooltip'

export interface ChartWrapperProps {
  chartConfig: ChartConfig
  multiSeriesDataChartConfig: MultiSeriesDataChartConfig
  singleSeriesDataChartConfig: SingleSeriesDataChartConfig
}

const ChartWrapper: React.FC<ChartWrapperProps> = ({
  chartConfig,
  multiSeriesDataChartConfig,
  singleSeriesDataChartConfig
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(CHART_TOOLTIP_I18N_KEY)

  const [chartWrapperLogic] = useChartWrapperLogic(chartConfig)

  const {
    chartDimension,
    chartPeriod,
    chartPeriodOptions,
    emptyState,
    hasEntries,
    isMultiSeriesData,
    isSingleColumnGrouped,
    showBarChart,
    showStackedBarChart
  } = chartConfig

  const {
    chartPeriodAnchorEl,
    handleBarLineChartTypeSwitch,
    handleStackedGroupedChartTypeSwitch,
    handleChartPeriodOptionsButtonClick,
    handleChartPeriodOptionsMenuItemClick,
    handleChartPeriodOptionsMenuClose
  } = chartWrapperLogic

  const multiSeriesBarCharts = useMemo(
    () => (
      <Grid>
        <ChartIconWrapper
          backgroundColor={showStackedBarChart ? 'white' : undefined}
          classes={classes}
          tooltipTitle={formatMessage('grouped_bar_chart')}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <img
            alt="Horizontal Grouped Bar Chart"
            src={!showStackedBarChart ? IMAGES.horizontalBarChartBlue : IMAGES.horizontalBarChartGrey}
            onClick={handleStackedGroupedChartTypeSwitch}
          />
        </ChartIconWrapper>
        <ChartIconWrapper
          backgroundColor={showStackedBarChart ? undefined : 'white'}
          classes={classes}
          tooltipTitle={formatMessage('stacked_bar_chart')}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <img
            alt="Horizontal Stacked Bar Chart"
            src={showStackedBarChart ? IMAGES.horizontalStackedBarChartBlue : IMAGES.horizontalStackedBarChartGrey}
            onClick={handleStackedGroupedChartTypeSwitch}
          />
        </ChartIconWrapper>
        {showStackedBarChart ? (
          <BitBarChart
            chartData={{
              datasets: multiSeriesDataChartConfig.data.datasets,
              labels: multiSeriesDataChartConfig.data.labels
            }}
            horizontalBarChart
            stacked
            chartDimension={chartDimension}
            emptyState={emptyState}
            hasEntries={hasEntries}
          />
        ) : (
          <BitBarChart
            chartData={{
              datasets: multiSeriesDataChartConfig.data.datasets,
              labels: multiSeriesDataChartConfig.data.labels
            }}
            horizontalBarChart
            chartDimension={chartDimension}
            emptyState={emptyState}
            hasEntries={hasEntries}
          />
        )}
      </Grid>
    ),
    [
      chartDimension,
      classes,
      emptyState,
      formatMessage,
      handleStackedGroupedChartTypeSwitch,
      hasEntries,
      multiSeriesDataChartConfig.data.datasets,
      multiSeriesDataChartConfig.data.labels,
      showStackedBarChart
    ]
  )

  const singleColumnGroupedCharts = useMemo(
    () => (
      <Grid className={classes.chartWrapper}>
        <ChartIconWrapper
          backgroundColor={!showBarChart ? undefined : 'white'}
          classes={classes}
          tooltipTitle={formatMessage('table_chart')}
        >
          <TableChartIcon
            color={!showBarChart ? 'primary' : undefined}
            className={classes.showChartIcon}
            onClick={handleBarLineChartTypeSwitch}
          />
        </ChartIconWrapper>
        <ChartIconWrapper
          backgroundColor={showBarChart ? undefined : 'white'}
          classes={classes}
          tooltipTitle={formatMessage('bar_chart')}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <img
            alt="Horizontal Bar Chart"
            src={showBarChart ? IMAGES.horizontalBarChartBlue : IMAGES.horizontalBarChartGrey}
            onClick={handleBarLineChartTypeSwitch}
          />
        </ChartIconWrapper>
        {showBarChart ? (
          <BitBarChart
            chartData={{
              datasets: singleSeriesDataChartConfig.data.barChartData.datasets,
              labels: singleSeriesDataChartConfig.data.barChartData.labels
            }}
            horizontalBarChart
            chartDimension={chartDimension}
            emptyState={emptyState}
            hasEntries={hasEntries}
            showFooter={false}
          />
        ) : (
          // TODO: replace this component once we implement table charts
          <BitBarChart
            chartData={{
              datasets: [],
              labels: []
            }}
            chartDimension={chartDimension}
            emptyState={{
              icon: <ConstructionIcon color="action" fontSize="large" />,
              text: 'Table chart coming soon!'
            }}
            hasEntries={false}
          />
        )}
      </Grid>
    ),
    [
      chartDimension,
      classes,
      emptyState,
      formatMessage,
      handleBarLineChartTypeSwitch,
      hasEntries,
      showBarChart,
      singleSeriesDataChartConfig.data.barChartData.datasets,
      singleSeriesDataChartConfig.data.barChartData.labels
    ]
  )

  const noGroupingCharts = useMemo(
    () => (
      <Grid className={classes.chartWrapper}>
        <ChartIconWrapper
          backgroundColor={!showBarChart ? undefined : 'white'}
          classes={classes}
          tooltipTitle={formatMessage('line_chart')}
        >
          <ShowChartIcon
            color={!showBarChart ? 'primary' : undefined}
            className={classes.showChartIcon}
            onClick={handleBarLineChartTypeSwitch}
          />
        </ChartIconWrapper>

        <ChartIconWrapper
          backgroundColor={showBarChart ? undefined : 'white'}
          classes={classes}
          tooltipTitle={formatMessage('bar_chart')}
        >
          <BarChartIcon
            color={showBarChart ? 'primary' : undefined}
            className={classes.showChartIcon}
            onClick={handleBarLineChartTypeSwitch}
          />
        </ChartIconWrapper>

        <Button
          className={`${classes.buttons} ${chartPeriodOptions.length === 1 && classes.buttonGreyText}`}
          size="medium"
          variant="text"
          endIcon={
            <DropDownArrow
              className={
                chartPeriodOptions.length === 1 ? classes.greyDropDownArrowIcon : classes.defaultDropDownArrowIcon
              }
            />
          }
          onClick={chartPeriodOptions.length === 1 ? undefined : handleChartPeriodOptionsButtonClick}
        >
          {`${upperFirst(chartPeriod.toLowerCase())} interval`}
        </Button>
        <Menu
          getContentAnchorEl={null}
          anchorEl={chartPeriodAnchorEl}
          keepMounted
          open={Boolean(chartPeriodAnchorEl)}
          onClose={handleChartPeriodOptionsMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {chartPeriodOptions.map((option: string) => (
            <MenuItem key={option} value={option} onClick={() => handleChartPeriodOptionsMenuItemClick(option)}>
              {`${upperFirst(option.toLowerCase())} interval`}
            </MenuItem>
          ))}
        </Menu>
        {showBarChart ? (
          <BitBarChart
            chartData={{
              datasets: singleSeriesDataChartConfig.data.barChartData.datasets,
              labels: singleSeriesDataChartConfig.data.barChartData.labels
            }}
            chartDimension={chartDimension}
            emptyState={emptyState}
            hasEntries={hasEntries}
            showFooter={false}
            barChartOptions={singleSeriesDataChartConfig.options?.barChartOptions.vertical}
          />
        ) : (
          <BitLineChart
            chartData={{
              datasets: singleSeriesDataChartConfig.data.lineChartData.datasets,
              labels: singleSeriesDataChartConfig.data.lineChartData.labels
            }}
            lineChartOptions={singleSeriesDataChartConfig.options?.lineChartOptions}
            chartDimension={chartDimension}
            emptyState={emptyState}
            hasEntries={hasEntries}
          />
        )}
      </Grid>
    ),
    [
      chartDimension,
      chartPeriod,
      chartPeriodAnchorEl,
      chartPeriodOptions,
      classes,
      emptyState,
      formatMessage,
      handleBarLineChartTypeSwitch,
      handleChartPeriodOptionsButtonClick,
      handleChartPeriodOptionsMenuClose,
      handleChartPeriodOptionsMenuItemClick,
      hasEntries,
      showBarChart,
      singleSeriesDataChartConfig.data.barChartData.datasets,
      singleSeriesDataChartConfig.data.barChartData.labels,
      singleSeriesDataChartConfig.data.lineChartData.datasets,
      singleSeriesDataChartConfig.data.lineChartData.labels,
      singleSeriesDataChartConfig.options
    ]
  )

  return useMemo(
    () => (
      <>
        {isMultiSeriesData && multiSeriesBarCharts}
        {isSingleColumnGrouped && singleColumnGroupedCharts}
        {!isSingleColumnGrouped && !isMultiSeriesData && noGroupingCharts}
      </>
    ),
    [isMultiSeriesData, isSingleColumnGrouped, multiSeriesBarCharts, noGroupingCharts, singleColumnGroupedCharts]
  )
}

export default ChartWrapper
