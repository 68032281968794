import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable } from 'global/types/dataTables/dataTables'
import { ATTACK_TYPES, ATTACK_TYPES_BACKEND_KEYWORDS } from 'global/lib/attackTypeValidator/attackTypeValidator'

import { DataTableWithFilterAndDemoState } from 'ets/redux/types/dataTables'

export const GRID_COLUMNS = {
  RECEIVED: 'received',
  RECIPIENTS: 'recipients',
  SAMPLE_RECIPIENT: 'sample_recipient',
  EMAIL: 'email',
  ATTACK_TYPE: 'attack_type',
  ACTION: 'action'
}

export const SORT_FIELDS = {
  [GRID_COLUMNS.RECEIVED]: 'date',
  [GRID_COLUMNS.RECIPIENTS]: 'count',
  [GRID_COLUMNS.SAMPLE_RECIPIENT]: 'displayName',
  [GRID_COLUMNS.EMAIL]: 'subject',
  [GRID_COLUMNS.ATTACK_TYPE]: 'taxonomy'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.RECEIVED]: {
    sortable: true,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.RECIPIENTS]: {
    isDescOrderFirst: true,
    sortable: true,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.SAMPLE_RECIPIENT]: {
    sortable: true,
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.EMAIL]: {
    sortable: true,
    orderIndex: 3,
    show: true
  },
  [GRID_COLUMNS.ATTACK_TYPE]: {
    sortable: true,
    orderIndex: 4,
    show: true
  },
  [GRID_COLUMNS.ACTION]: {
    sortable: false,
    orderIndex: 5,
    show: true
  }
}

const ALL_THREATS = 'all_threats'
const ADVANCED_THREATS = 'advanced_threats'
const PREDEF_FILTER = ['isBootstrap:true']
// The query filter in api.pipeline.service is unable to construct this more complex query so it's better to build this here
// we want to wrap the taxonomy terms in "" so that the backend knows to do a equals query instead of a like
const ADVANCED_THREATS_FILTER = [
  '(taxonomy:"conversationHijacking" OR taxonomy:"blackmail" OR taxonomy:"becSpoofing" OR taxonomy:"phishing") AND isBootstrap:true'
]

export const FILTER_CONFIG = {
  [ADVANCED_THREATS]: {
    filterQuery: ADVANCED_THREATS_FILTER
  },
  [ALL_THREATS]: {
    filterQuery: PREDEF_FILTER
  },
  ...Object.values(ATTACK_TYPES).reduce(
    (all: any, attackType: string) => ({
      ...all,
      [attackType]: {
        // we want to wrap the taxonomy terms in "" so that the backend knows to do a equals query instead of a like
        filterQuery: [...PREDEF_FILTER, `taxonomy:"${ATTACK_TYPES_BACKEND_KEYWORDS[attackType]}"`]
      }
    }),
    {}
  )
}

export const DEMO_DATA = {
  ALL: 123456,
  COUNTS: {}
}

const ITEMS_PER_PAGE = 10
const PDF_ITEMS_PER_PAGE = 10

// initialState
export const INITIAL_STATE: DataTableWithFilterAndDemoState = {
  SORT_FIELDS,
  DEMO_DATA,
  GRID_COLUMNS,
  ADVANCED_THREATS,
  ALL_THREATS,
  FILTER_CONFIG,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: FILTER_CONFIG[ADVANCED_THREATS].filterQuery,
  search: '',
  sort: [{ field: GRID_COLUMNS.RECEIVED, dir: 'desc' }],
  pdfConfig: {
    SORT_FIELDS,
    skip: 0, // offset
    take: PDF_ITEMS_PER_PAGE, // limit
    filter: FILTER_CONFIG[ADVANCED_THREATS].filterQuery,
    search: '',
    sort: [{ field: GRID_COLUMNS.RECEIVED, dir: 'desc' }]
  }
}

export const threatsSlice = createSlice({
  name: 'DATATABLE/THREATS',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableWithFilterAndDemoState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = threatsSlice.actions

export default threatsSlice.reducer
